// Declare all application const.

// export const API_URL = "https://4c1f453ebf09.ngrok.io/api";
// export const API_URL = "https://eb323ea6f091.ngrok.io/rlapi/api";
// export const RecordLincURL = "https://eb323ea6f091.ngrok.io/";
// export const API_URL = "https://recordlinc.com/rlapi/api";
// export const API_URL = "https://qa.recordlinc.com/rlapi/api";
// export const RecordLincURL = "https://qa.recordlinc.com/";
// export const RecordLincURL = "https://qa.recordlinc.com/";
// export const API_URL = "http://localhost:56697/api";
//export const API_URL = "https://4c1f453ebf09.ngrok.io/api";
//export const RecordLincURL = "https://recordlinc.com/";

export const RecordLincURL = "https://recordlinc.com/";
export const API_URL = "https://recordlinc.com/rlapi/api";

//Theme constant
export const Title = "Sunbit";
export const Logo = "//refer.oneclickreferral.com/Images/sunbit.png";
export const LogoWithLabel = "//refer.oneclickreferral.com/Images/sunbit.png";
export const ProductName = "Sunbit";
export const FavIcon = "//refer.oneclickreferral.com/Images/sunbit.png";

// Set comman Header value for API calls
export const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
    access_token: localStorage.getItem("token"),
  },
};

export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
