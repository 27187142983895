import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  // NavLink,
} from "reactstrap";
export default function TopNavBar(props) {
  const [integration, setIntegration] = useState(false);

  useEffect(() => {
    if (
      window.location.href.indexOf("manage") > -1 ||
      window.location.href.indexOf("integration") > -1
    ) {
      setIntegration(true);
    }
  }, [window.location.href]);

  return (
    <Nav className="setting-nav-menu">
      <NavItem>
        <NavLink
          exact
          to="/setting/integration"
          className={integration ? "active" : ""}
          href="/setting/integration"
        >
          Integrations
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/profile" href="/setting/profile">
          Profile
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/location" href="/setting/location">
          Locations
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/billing" href="/setting/billing">
          Billing
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          exact
          to="/setting/accountsetting"
          href="/setting/accountsetting"
        >
          Account Settings
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/teammember" href="/setting/teammember">
          Team Members
        </NavLink>
      </NavItem>
      {/* <NavItem>
            <NavLink exact to="/setting/changepassword" href="/setting/changepassword">Changes Password</NavLink>
            </NavItem> */}
      <NavItem>
        <NavLink exact to="/setting/comingSoon" href="/setting/comingSoon">
          Support
        </NavLink>
      </NavItem>
    </Nav>
  );
}
