import React, { useState, useEffect } from "react";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import EditMemberModal from "../models/EditMemberModal";
import { toast } from "react-toastify";
import RemoveMemberModal from "../models/RemoveMemberModal";
import TopNavBar from "../common-components/TopNavbar";
import {
  Collapse,
  Navbar,
  Row,
  Col,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import AppPageLoader from "../common-components/AppPageLoader";

import Api from "../API/team-members";

export default function TeamMember() {
  const [EditMemberModalShow, SetEditMemberModal] = React.useState(false);
  const [RemoveMemberModalShow, SetRemoveMemberModal] = React.useState(false);
  const [teamMeambers, SetTeamMeambers] = React.useState([]);

  const [loader, SetLoader] = React.useState(true);
  const [isAllSelected, SetIsAllSelected] = React.useState(false);

  const [searchString, SetSearchString] = React.useState("");

  const [filters, SetFilters] = React.useState({
    UserId: 0,
    PageIndex: 1,
    PageSize: 10,
    SearchText: "",
    SortColumn: 0,
    SortDirection: 0,
    LocationBy: 0,
    ProviderTypefilter: 0,
    IncludeUser: true,
  });

  const [pages, setPages] = React.useState(0);

  const [sortingColumn, setSortingColumn] = React.useState({
    lastName: { id: 2, sortDir: 0 },
    firstName: { id: 1, sortDir: 0 },
    email: { id: 3, sortDir: 0 },
    speciality: { id: 4, sortDir: 0 },
    location: { id: 2, sortDir: 0 },
  });

  // const [currentSortColumn, setCurrentSortColumn] = React.useState(null);

  const [addresses, setAddresses] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [selectedMemberId, SetSelectedMemberId] = useState(null);

  const setSelectedTeamMember = (id) => {
    SetSelectedMemberId(id);
  };

  const setCurrentPage = (event, index) => {
    event.preventDefault();
    SetFilters((filters) => ({
      ...filters,
      PageIndex: index,
    }));
  };

  const editBtnClicked = (id) => {
    setSelectedTeamMember(id);
    SetEditMemberModal(true);
  };

  const deletBtnClicked = (id) => {
    setSelectedTeamMember(id);
    SetRemoveMemberModal(true);
  };

  const setCurrentSortColumn = (column) => {
    if (column) {
      let data = {
        lastName: { id: 2, sortDir: 0 },
        firstName: { id: 1, sortDir: 0 },
        email: { id: 3, sortDir: 0 },
        speciality: { id: 4, sortDir: 0 },
        location: { id: 2, sortDir: 0 },
      };

      if (
        sortingColumn[column].sortDir == 2 ||
        sortingColumn[column].sortDir == 0
      ) {
        data[column].sortDir = 1;
      } else {
        data[column].sortDir = 2;
      }

      SetFilters((filters) => ({
        ...filters,
        SortColumn: data[column].id,
        SortDirection: data[column].sortDir,
      }));
      setSortingColumn(data);
    }
  };

  const onEditMemberModal = (isRelod) => {
    setSelectedTeamMember(null);
    SetEditMemberModal(false);
    if (isRelod) {
      getTeamMembers();
    }
  };

  const onRemoveMemberModal = (isRelod) => {
    setSelectedTeamMember(null);
    SetRemoveMemberModal(false);
    if (isRelod) {
      getTeamMembers();
    }
  };

  const locationChange = (event) => {
    event.preventDefault();
    SetFilters({ ...filters, PageIndex: 1, LocationBy: event.target.value });
  };

  const memberTypeChange = (event) => {
    event.preventDefault();
    SetFilters({
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: parseInt(event.target.value),
    });
  };

  const searchStringChange = (event) => {
    event.preventDefault();
    SetSearchString(event.target.value);
  };

  const searchForTextValue = (event) => {
    event.preventDefault();
    SetFilters({
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: 0,
      LocationBy: 0,
      SearchText: searchString,
    });
  };

  const getAddress = (id) => {
    if (id > 0) {
      Api.getAddress(id)
        .then((res) => {
          let result = res.data.Result;
          if (result.length > 0) {
            result.sort((a, b) => b.ContactType - a.ContactType);
          }
          setAddresses(result);
        })
        .catch((error) => {});
    }
  };

  const getPagination = () => {
    let list = [];
    list.push(
      <li class="page-item">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          onClick={(e) => setCurrentPage(e, 1)}
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
    );
    for (let index = 1; index <= pages; index++) {
      list.push(
        <li class="page-item">
          <a
            class="page-link"
            href="#"
            aria-label="Previous"
            onClick={(e) => setCurrentPage(e, index)}
          >
            <span aria-hidden="true">{index}</span>
          </a>
        </li>
      );
    }
    list.push(
      <li class="page-item">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          onClick={(e) => setCurrentPage(e, pages)}
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    );
    return <ul className="pagination my-2 ml-auto">{list}</ul>;
  };

  const getTeamMembers = () => {
    SetLoader(true);
    SetTeamMeambers([]);
    SetIsAllSelected(false);
    setPages(0);
    Api.getTeamMembers(filters)
      .then((resp) => {
        let teamMeambers = [];
        for (let index = 0; index < resp.data.length; index++) {
          let item = resp.data[index];
          item.isSelected = false;
          if (user.UserId != item.TeamMemberId) {
            teamMeambers.push(item);
          }
        }
        let totalNumberOfRecord = resp.data[0].TotalCount;
        let pages = 0;
        if (totalNumberOfRecord) {
          pages = Math.ceil(totalNumberOfRecord / filters.PageSize);
        }
        setPages(pages);
        SetTeamMeambers(teamMeambers);
        SetLoader(false);
      })
      .catch((error) => {
        SetLoader(false);
      });
  };

  const selectUnSelectAllRecords = (event) => {
    event.preventDefault();
    SetIsAllSelected(!isAllSelected);
  };

  const selectUnSelectRecords = (index) => {
    let TeamMemberIds = [];

    let data = [...teamMeambers];
    data[index].isSelected = !data[index].isSelected;
    SetTeamMeambers(data);

    for (let index = 0; index < teamMeambers.length; index++) {
      const element = teamMeambers[index];
      if (element.isSelected) {
        TeamMemberIds.push(element.TeamMemberId);
      }
    }

    if (teamMeambers.length == TeamMemberIds.length) {
      SetIsAllSelected(true);
    } else {
      SetIsAllSelected(false);
    }
  };

  const deleteSelectedRecords = () => {
    let TeamMemberIds = [];

    for (let index = 0; index < teamMeambers.length; index++) {
      const element = teamMeambers[index];
      if (element.isSelected) {
        TeamMemberIds.push(element.TeamMemberId);
      }
    }
    if (TeamMemberIds.length > 0) {
      let payLoad = { TeamMemberId: TeamMemberIds };
      Api.removeTeamMemer(payLoad)
        .then(() => {
          toast.success("Remove successfully!");
          getTeamMembers();
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } else {
      toast.info("Select at least team member!");
    }
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    if (user.UserId != 0) {
      getAddress(user.UserId);
      SetFilters({ ...filters, UserId: user.UserId });
    }
  }, [user.UserId]);

  useEffect(() => {
    if (user.UserId != 0) {
      getTeamMembers();
    }
  }, [filters]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < teamMeambers.length; index++) {
      let item = teamMeambers[index];
      if (isAllSelected) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      data.push(item);
    }
    SetTeamMeambers(data);
  }, [isAllSelected]);

  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="setting-page team-member-page position-relative">
              <div className="white-container coming-soon">
                {/* <div className="page-info">
                  <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active" aria-current="page">
                        Team Members
                      </li>
                    </ol>
                  </nav>
                </div> */}
                <div className="w-100 setting-menu">
                  <Row>
                    <Col sm="12">
                      <div className="billing-page">
                        <div className="grid-section">
                          <div className="white-container p-0">
                            <div className="action-row d-flex pt-0">
                              <div className="left-box d-flex">
                                <div
                                  className="form-group app-select"
                                  style={{ width: "226px" }}
                                >
                                  <select
                                    className="form-control"
                                    value={filters.LocationBy}
                                    name="Addressess"
                                    onChange={locationChange}
                                  >
                                    <option value="0">All Location</option>
                                    {addresses.length > 0 &&
                                      addresses.map((address) => (
                                        <option value={address.Id}>
                                          {address.Name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div
                                  className="form-group app-select"
                                  style={{ width: "226px" }}
                                >
                                  <select
                                    className="form-control"
                                    value={filters.ProviderTypefilter}
                                    onChange={memberTypeChange}
                                  >
                                    <option value="0">
                                      Select Staff/Team Member
                                    </option>
                                    <option value="2">Only Staff</option>
                                    <option value="1">Only Team Member</option>
                                  </select>
                                </div>
                              </div>
                              <div className="right-box ml-auto d-flex align-items-start">
                                <form onSubmit={searchForTextValue}>
                                  <div className="form-group input-group app-search">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      value={searchString}
                                      onChange={searchStringChange}
                                    />
                                    <span className="search-icon">
                                      <i className="fa fa-search"></i>
                                    </span>
                                    <button
                                      className="btn search-btn"
                                      type="submit"
                                    >
                                      <span>Search</span>
                                      <i className="fa fa-search"></i>
                                    </button>
                                  </div>
                                </form>
                                {/* <NavLink
                                      exact
                                      to="/billing/paymentmethod"
                                      className="btn app-btn small-btn text-uppercase"
                                    >
                                      Payment Method
                                    </NavLink> */}
                                {/* <button className="btn app-btn small-btn text-uppercase">Payment Method</button> */}
                              </div>
                            </div>
                            <div className="select-action-row d-flex">
                              <Navbar color="light ml-auto" light expand="md">
                                <Collapse className="show" navbar>
                                  <Nav className="mr-auto" navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                      <DropdownToggle nav caret>
                                        Options
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <DropdownItem
                                          onClick={() =>
                                            editBtnClicked()
                                          }
                                        >
                                          Add Member
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={deleteSelectedRecords}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </Nav>
                                </Collapse>
                              </Navbar>
                            </div>
                            <div className="app-table">
                              <div className="table-responsive">
                                <table className="table table-hover table-borderless invoice-grid">
                                  <thead>
                                    <tr>
                                      <th className="chk-col">
                                        <div
                                          className="custom-control custom-checkbox app-checkbox"
                                          onClick={selectUnSelectAllRecords}
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="chkall"
                                            checked={isAllSelected}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="chkall"
                                          ></label>
                                        </div>
                                      </th>
                                      <th className="shorting">
                                        Last Name
                                        <button
                                          onClick={() =>
                                            setCurrentSortColumn("lastName")
                                          }
                                          className="shorting-btn"
                                        >
                                          <FeatherIcon
                                            icon={
                                              sortingColumn.lastName.sortDir ==
                                              2
                                                ? "chevron-up"
                                                : "chevron-down"
                                            }
                                            size="20"
                                          />
                                        </button>
                                      </th>

                                      <th className="shorting">
                                        First Name
                                        <button
                                          onClick={() =>
                                            setCurrentSortColumn("firstName")
                                          }
                                          className="shorting-btn"
                                        >
                                          <FeatherIcon
                                            icon={
                                              sortingColumn.firstName.sortDir ==
                                              2
                                                ? "chevron-up"
                                                : "chevron-down"
                                            }
                                            size="20"
                                          />
                                        </button>
                                      </th>

                                      <th className="shorting">
                                        Email
                                        <button
                                          onClick={() =>
                                            setCurrentSortColumn("email")
                                          }
                                          className="shorting-btn"
                                        >
                                          <FeatherIcon
                                            icon={
                                              sortingColumn.email.sortDir == 2
                                                ? "chevron-up"
                                                : "chevron-down"
                                            }
                                            size="20"
                                          />
                                        </button>
                                      </th>
                                      <th className="shorting">
                                        Speciality
                                        <button
                                          onClick={() =>
                                            setCurrentSortColumn("speciality")
                                          }
                                          className="shorting-btn"
                                        >
                                          <FeatherIcon
                                            icon={
                                              sortingColumn.speciality
                                                .sortDir == 2
                                                ? "chevron-up"
                                                : "chevron-down"
                                            }
                                            size="20"
                                          />
                                        </button>
                                      </th>
                                      <th className="shorting">
                                        Location
                                        <button
                                          onClick={() =>
                                            setCurrentSortColumn("location")
                                          }
                                          className="shorting-btn"
                                        >
                                          <FeatherIcon
                                            icon={
                                              sortingColumn.location.sortDir ==
                                              2
                                                ? "chevron-up"
                                                : "chevron-down"
                                            }
                                            size="20"
                                          />
                                        </button>
                                      </th>
                                      <th className="shorting">Type</th>
                                      <th className="shorting">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {teamMeambers.length > 0 ? (
                                      teamMeambers.map((item, index) => (
                                        <tr key={item.TeamMemberId}>
                                          <td className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                onClick={() =>
                                                  selectUnSelectRecords(index)
                                                }
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={item.TeamMemberId}
                                                checked={item.isSelected}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.TeamMemberId}
                                              ></label>
                                            </div>
                                          </td>
                                          <td>{item.LastName}</td>
                                          <td>{item.FirstName}</td>
                                          <td>{item.Email}</td>
                                          <td>{item.Specialty}</td>
                                          <td>{item.Location}</td>
                                          <td>
                                            {item.ProviderType == 2
                                              ? "Staff"
                                              : "Provider"}
                                          </td>
                                          <td>
                                            <button
                                              className="action-btn app-btn-clear"
                                              onClick={() =>
                                                editBtnClicked(
                                                  item.TeamMemberId
                                                )
                                              }
                                            >
                                              <FeatherIcon
                                                icon="edit-2"
                                                size="20"
                                              />
                                            </button>
                                            <button
                                              className="action-btn app-btn-clear"
                                              onClick={() =>
                                                deletBtnClicked(
                                                  item.TeamMemberId
                                                )
                                              }
                                            >
                                              <FeatherIcon
                                                icon="trash-2"
                                                size="20"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="8"
                                          style={{ textAlign: "center" }}
                                        >
                                          No record found!
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {pages != 0 && (
                              <nav
                                className="d-flex"
                                aria-label="Page navigation example"
                              >
                                {getPagination()}
                              </nav>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {loader && <AppPageLoader />}
            </div>
          </div>
        </div>
      </main>
      <EditMemberModal
        show={EditMemberModalShow}
        memeberid={selectedMemberId}
        onHide={onEditMemberModal}
      />
      <RemoveMemberModal
        show={RemoveMemberModalShow}
        memeberid={selectedMemberId}
        onHide={onRemoveMemberModal}
      />
    </div>
  );
}
