import { history } from "../history";
// Check is error or not
export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

// Redirect to forgot password
export const gotoForgotPassword = () => {
  history.push("/forgotpassword");
};
// Redirect to sign up
export const gotoSignUp = () => {
  history.push("/signup");
};
// Redirect to dashboard
export const gotoDashboard = () => {
  history.push("/dashboard");
};

// redirect to signup
export const goToSignin = () => {
  history.push("/signin");
};
