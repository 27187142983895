import React, { useState , useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import FeatherIcon from 'feather-icons-react';
import classnames from "classnames";
import Switch from "react-switch";
// component imports
import AppSidebarNavbar from '../common-components/AppSidebarNavbar';
import TopNavBar from "../common-components/TopNavbar";
import AddLocationModel from '../models/AddLocationModel';
//images import
import DownArrowIcon from '../assets/images/icon-donarrow-green.svg';
import PrintIcon from '../assets/images/icon-print-green.svg';
import PaymentCardImg from '../assets/images/paymentcard-img.svg';
import WifiSignalImg from '../assets/images/wifi-signal-img.svg';
import VisaCardImg from '../assets/images/visa-card-img.svg';
import CvvImg from '../assets/images/cvv-img.svg';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    // NavLink,
    Card,
    CardTitle,
    CardText,
    Row,
    Col,
} from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export default function BillingPage() {
    const [MonthSwitch, SetMonthSwitch] = React.useState(false);
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [AddLocationModelShow, SetAddLocationModel,] = React.useState(false);

    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState({
        nr1: false,
        nr2: false,
        nr3: false,
        nr4: false,
        nr5: false,
        nr6: false,
        nr7: false,
        nr8: false,
        nr9: false,
        nr10: false,
        nr11: false,
    });

    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });
    };

    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
            newState[inputName] = value;
            }
            return newState;
        });
    };

    useEffect(() => {
        let allChecked = true;
        for (const inputName in checked) {
          if (checked[inputName] === false) {
            allChecked = false;
          }
        }
        if (allChecked) {
          setCheckedAll(true);
        } else {
          setCheckedAll(false);
        }
      }, [checked]);

    return (
        <div className="main-wrapper">
            <AppSidebarNavbar activeTabsBilling={true} />
            <main>
                <div className="app-container">

                    <div className="app-content">
                        <TopNavBar />
                        <div className="billing-page">
                            <div className="grid-section">
                                <div className="white-container">

                                    <div className="action-row d-flex justify-content-end">
                                        <div className="left-box d-flex">
                                            <div className="form-group app-select">
                                                <select className="form-control">
                                                    <option>Select a Month</option>
                                                    <option>January</option>
                                                    <option>February</option>
                                                    <option>March</option>
                                                    <option>April</option>
                                                    <option>May</option>
                                                    <option>June</option>
                                                    <option>July</option>
                                                    <option>August</option>
                                                    <option>September</option>
                                                    <option>October</option>
                                                    <option>November</option>
                                                    <option>December</option>
                                                </select>
                                            </div>
                                            <div className="form-group app-select">
                                                <select className="form-control">
                                                    <option>Select a Year</option>
                                                    <option>2021</option>
                                                    <option>2020</option>
                                                    <option>2019</option>
                                                    <option>2018</option>
                                                    <option>2017</option>
                                                    <option>2016</option>
                                                    <option>2015</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="right-box d-flex align-items-start">
                                            <NavLink exact to="/billing/paymentmethod" className="btn app-btn small-btn text-uppercase">Payment Method</NavLink>
                                        </div>
                                    </div>
                                    <div className="app-table">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-borderless invoice-grid">
                                                <thead>
                                                    <tr>
                                                        <th className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chkall" 
                                                                    onChange={(event) => selectAll(event.target.checked)}
                                                                    checked={checkedAll}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chkall"></label>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            Invoice Date
                                                        </th>

                                                        <th>
                                                            Invoice Total
                                                        </th>
                                                        <th>
                                                            Invoice Status
                                                        </th>
                                                        <th>
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk1"
                                                                    name="nr1"
                                                                    onChange={() => toggleCheck("nr1")}
                                                                    checked={checked["nr1"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk1"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Aug, 2020
                                                        </td>

                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk2"
                                                                    name="nr2"
                                                                    onChange={() => toggleCheck("nr2")}
                                                                    checked={checked["nr2"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk2"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Sep, 2020
                                                        </td>

                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-danger">Refund</span>

                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk3"
                                                                    name="nr3"
                                                                    onChange={() => toggleCheck("nr3")}
                                                                    checked={checked["nr3"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk3"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Oct, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk4"
                                                                    name="nr4"
                                                                    onChange={() => toggleCheck("nr4")}
                                                                    checked={checked["nr4"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk4"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Aug, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-danger">Refund</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk5"
                                                                    name="nr5"
                                                                    onChange={() => toggleCheck("nr5")}
                                                                    checked={checked["nr5"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk5"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Sep, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk6"
                                                                    name="nr6"
                                                                    onChange={() => toggleCheck("nr6")}
                                                                    checked={checked["nr6"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk6"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Oct, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-danger">Refund</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk7"
                                                                    name="nr7"
                                                                    onChange={() => toggleCheck("nr7")}
                                                                    checked={checked["nr7"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk7"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Aug, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk8"
                                                                    name="nr8"
                                                                    onChange={() => toggleCheck("nr8")}
                                                                    checked={checked["nr8"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk8"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Sep, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-danger">Refund</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk9"
                                                                    name="nr9"
                                                                    onChange={() => toggleCheck("nr9")}
                                                                    checked={checked["nr9"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk9"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Oct, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk10"
                                                                    name="nr10"
                                                                    onChange={() => toggleCheck("nr10")}
                                                                    checked={checked["nr10"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk10"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Sep, 2020
                                                        </td>
                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-danger">Refund</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chk-col">
                                                            <div className="custom-control custom-checkbox app-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id="chk11"
                                                                    name="nr11"
                                                                    onChange={() => toggleCheck("nr11")}
                                                                    checked={checked["nr11"]}
                                                                />
                                                                <label className="custom-control-label" htmlFor="chk11"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            01 Oct, 2020
                                                        </td>

                                                        <td>
                                                            $ 1,648
                                                        </td>
                                                        <td>
                                                            <span className="app-badge app-badge-success">Paid</span>
                                                        </td>
                                                        <td>
                                                            {/* <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="arrow-down" size="20" /> 
                                                            </button>
                                                            <button className="action-btn app-btn-clear">
                                                                <FeatherIcon icon="printer" size="20" /> 
                                                            </button> */}
                                                            <a className="pay-btn" href="javascript:void(0)" >Pay</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="white-container">
                                    <div className="page-info">
                                        <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active" aria-current="page">Available Plan</li>
                                            </ol>
                                        </nav>
                                        <div className="bill-loggal">
                                            <div className="month-year-switch">
                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plan-detail-section">
                                    <Nav tabs>
                                            <NavItem>
                                                <Link
                                                    className={classnames({ active: activeTab === "1" })}
                                                    onClick={() => {
                                                    toggle("1");
                                                    }}
                                                >
                                                    For Product, Design, & Marketing
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link
                                                    className={classnames({ active: activeTab === "2" })}
                                                    onClick={() => {
                                                    toggle("2");
                                                    }}
                                                >
                                                    For Sales & Customer Success
                                                </Link>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col md={3} xl={3}>
                                                        <div className="plan-detail">
                                                            <div className="plan-price">
                                                                <sapn className="price">$0</sapn>
                                                                <span className="plan-time"> license / month</span>
                                                            </div>
                                                            <h2 className="plan-head">Free</h2>
                                                            <p>Collaborative Note-taking, Call Listening & Collaboration</p>
                                                            <ul className="check-mark-list">
                                                                <li>Collaborative Note and Templates</li>
                                                                <li>Call Listening and Collaboration</li>
                                                                <li>Transcribe uo to 10 meeting/mo/user</li>
                                                                <li>Record up to 35 mins/meeting</li>
                                                            </ul>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">Always Available</button>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">See All Features</button>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} xl={3}>
                                                        <div className="plan-detail">
                                                            <div className="plan-price">
                                                                <sapn className="price">$20</sapn>
                                                                <span className="plan-time"> license / month</span>
                                                            </div>
                                                            <h2 className="plan-head">Starter</h2>
                                                            <p>Collaborative Note-taking, Call Listening & Collaboration</p>
                                                            <ul className="check-mark-list">
                                                                <li>Collaborative Note and Templates</li>
                                                                <li>Call Listening and Collaboration</li>
                                                                <li>Transcribe uo to 10 meeting/mo/user</li>
                                                                <li>Record up to 35 mins/meeting</li>
                                                            </ul>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">Always Available</button>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">See All Features</button>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} xl={3}>
                                                        <div className="plan-detail">
                                                            <div className="plan-price">
                                                                <sapn className="price">$45</sapn>
                                                                <span className="plan-time"> license / month</span>
                                                            </div>
                                                            <h2 className="plan-head">Plus</h2>
                                                            <p>Collaborative Note-taking, Call Listening & Collaboration</p>
                                                            <ul className="check-mark-list">
                                                                <li>Collaborative Note and Templates</li>
                                                                <li>Call Listening and Collaboration</li>
                                                                <li>Transcribe uo to 10 meeting/mo/user</li>
                                                                <li>Record up to 35 mins/meeting</li>
                                                            </ul>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">Always Available</button>
                                                            <button type="button" className="btn app-btn large-btn mr-2 mt-2 w-100">See All Features</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <AddLocationModel show={AddLocationModelShow} onHide={() => SetAddLocationModel(false)}
            />
        </div>
    );
}