import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

//component import
import * as inputFun from "../common-components/FormInputFunctions";
import { RecordLincURL } from "../config/appConstatnts";

import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
export default function WidgetMenu(props) {
  const [profileName, setProfileName] = React.useState({});
  const [userWidget, setUserWidget] = React.useState({
    PatientForms: "",
    SpecialOffers: "",
    ReferPatient: "",
    Reviews: "",
    AppointmentBooking: "",
    PatientLogin: "",
  });
  const coppyMessage = () => {
    toast.info("Key copied.", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    let obj = {
      PatientForms: "",
      SpecialOffers: "",
      ReferPatient: "",
      Reviews: "",
      AppointmentBooking: "",
    };

    obj.PatientForms =
      "<iframe src='" +
      RecordLincURL +
      "widget/" +
      props.profileName +
      "/PatientForms' width='50%' height='1200' frameborder='0' style='border:0px'></iframe>";

    obj.SpecialOffers =
      "<iframe src='" +
      RecordLincURL +
      "widget/" +
      props.profileName +
      "/SpecialOffers' width='50%' height='1200' frameborder='0' style='border:0px'></iframe>";

    obj.ReferPatient =
      "<iframe src='" +
      RecordLincURL +
      "widget/" +
      props.profileName +
      "/ReferPatient' width='50%' height='1200' frameborder='0' style='border:0px'></iframe>";

    obj.Reviews =
      "<iframe src='" +
      RecordLincURL +
      "widget/" +
      props.profileName +
      "/reviews' width='50%' height='1200' frameborder='0' style='border:0px'></iframe>";

    obj.AppointmentBooking =
      "<iframe src='" +
      RecordLincURL +
      "widget/" +
      props.profileName +
      "/AppointmentBooking' width='50%' height='1200' frameborder='0' style='border:0px'></iframe>";
    setUserWidget(obj);
  }, [props.profileName]);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Widget Menu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
                <p className="mb-3 mt-2">
                  Copy and paste HTML to display widget on your site
                </p>
                <FormGroup>
                  <Label for="exampleText">
                    Patient Forms
                    <CopyToClipboard
                      text={userWidget.PatientForms}
                      onCopy={coppyMessage}
                    >
                      <span>
                        <i className=" ml-3 fa fa-files-o"></i>
                      </span>
                    </CopyToClipboard>
                  </Label>
                  <Input
                    type="textarea"
                    value={userWidget.PatientForms}
                    name="text"
                    id="exampleText"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleText">
                    Special Offer
                    <CopyToClipboard
                      text={userWidget.SpecialOffers}
                      onCopy={coppyMessage}
                    >
                      <span>
                        <i className=" ml-3 fa fa-files-o"></i>
                      </span>
                    </CopyToClipboard>
                  </Label>
                  <Input
                    type="textarea"
                    value={userWidget.SpecialOffers}
                    name="text"
                    id="exampleText"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleText">
                    Reviews
                    <CopyToClipboard
                      text={userWidget.Reviews}
                      onCopy={coppyMessage}
                    >
                      <span>
                        <i className=" ml-3 fa fa-files-o"></i>
                      </span>
                    </CopyToClipboard>
                  </Label>
                  <Input
                    type="textarea"
                    value={userWidget.Reviews}
                    name="text"
                    id="exampleText"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleText">
                    Appointment
                    <CopyToClipboard
                      text={userWidget.AppointmentBooking}
                      onCopy={coppyMessage}
                    >
                      <span>
                        <i className=" ml-3 fa fa-files-o"></i>
                      </span>
                    </CopyToClipboard>
                  </Label>
                  <Input
                    type="textarea"
                    value={userWidget.AppointmentBooking}
                    name="text"
                    id="exampleText"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleText">
                    Refer Patient
                    <CopyToClipboard
                      text={userWidget.ReferPatient}
                      onCopy={coppyMessage}
                    >
                      <span>
                        <i className=" ml-3 fa fa-files-o"></i>
                      </span>
                    </CopyToClipboard>
                  </Label>
                  <Input
                    type="textarea"
                    value={userWidget.ReferPatient}
                    name="text"
                    id="exampleText"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                close
              </button>
              {/* <button type="button" className="btn app-btn large-btn ">
                Save
              </button> */}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
