import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "reactstrap";

import "../assets/css/flags.css";
import Api from "../API/integration";

import {
  validEmailRegex,
  validCharRegex,
  validPhoneRegex,
  validPasswordRegex,
  validZipCodeRegex,
} from "../config/appRegex";
import { validateForm } from "../config/commonFun";

export default function EmailInstallInstructionModel(props) {
  const countryOptions = [
    { value: "in", label: "+91", customAbbreviation: "in" },
    { value: "us", label: "+1", customAbbreviation: "us" },
    { value: "uk", label: "+44", customAbbreviation: "uk" },
  ];

  const [loader, setLoader] = useState(false);

  const [emailData, setEmailData] = useState({
    IntegrationId: 0,
    Name: "",
    Email: "",
    PhoneNumber: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
  });

  const handelChange = (event) => {
    let { name, value } = event.target;
    let errorValue = "";

    setEmailData((emailData) => ({
      ...emailData,
      [name]: value,
    }));

    switch (name) {
      case "Name":
        if (value.length == 0) {
          errorValue = "Name is required.";
        }
        break;

      case "Email":
        if (value.length == 0) {
          errorValue = "Email is required.";
        } else if (!validEmailRegex.test(value)) {
          errorValue = "Enter valid email.";
        }
        break;
      case "PhoneNumber":
        if (value.length == 0) {
          errorValue = "Phone number is required.";
        } else if (!validPhoneRegex.test(value)) {
          errorValue = "Enter valid phone number.";
        }
        break;
      default:
        break;
    }

    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;

    // Check validation
    if (emailData.Name.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["Name"]: "Name is required.",
      }));
      flagError = true;
    }

    if (emailData.Email.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["Email"]: "Email is required.",
      }));
      flagError = true;
    } else if (!validEmailRegex.test(emailData.Email)) {
      setErrors((errors) => ({
        ...errors,
        ["Email"]: "Enter valid email.",
      }));
      flagError = true;
    }

    if (emailData.PhoneNumber.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["PhoneNumber"]: "Phone number is required.",
      }));
      flagError = true;
    } else if (!validPhoneRegex.test(emailData.PhoneNumber)) {
      setErrors((errors) => ({
        ...errors,
        ["PhoneNumber"]: "Enter valid phone number.",
      }));
      flagError = true;
    }
    // Login User
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.sendEmail(emailData)
        .then((res) => {
          setLoader(false);
          toast.success("Email Sent.");
          props.onHide();
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong");
        });
    }
  };

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div className="d-flex country-option">
      <span className={`flag flag-${customAbbreviation}`}></span>
      <div className="code-txt">{label}</div>
    </div>
  );

  useEffect(() => {
    setEmailData((emailData) => ({
      ...emailData,
      IntegrationId: props.integrationId,
    }));
  }, [props.integrationId]);

  useEffect(() => {
    setEmailData({
      IntegrationId: props.integrationId,
      Name: "",
      Email: "",
      PhoneNumber: "",
    });

    setErrors({
      Name: "",
      Email: "",
      PhoneNumber: "",
    });
  }, [props.modelStatus]);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Email Install Instruction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <p class="icon-label pl-0 required">Name</p>
                  <div className="info-ctrl mb-3">
                    <input
                      type="text"
                      className="form-control pr-4"
                      name="Name"
                      value={emailData.Name}
                      onChange={handelChange}
                    />
                    {errors.Name.length > 0 && (
                      <div className="err-message">{errors.Name}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <p class="icon-label pl-0  required">Email</p>
                  <div className="info-ctrl mb-3">
                    <input
                      type="email"
                      className="form-control pr-4"
                      name="Email"
                      value={emailData.Email}
                      onChange={handelChange}
                    />
                    {errors.Email.length > 0 && (
                      <div className="err-message">{errors.Email}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <p class="icon-label pl-0 required">Phone</p>
                  <div className="info-ctrl mb-3">
                    <input
                      type="text"
                      className="form-control "
                      name="PhoneNumber"
                      value={emailData.PhoneNumber}
                      onChange={handelChange}
                    />
                    {errors.PhoneNumber.length > 0 && (
                      <div className="err-message">{errors.PhoneNumber}</div>
                    )}
                    {/* <Select
                      defaultValue={countryOptions[1]}
                      formatOptionLabel={formatOptionLabel}
                      options={countryOptions}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center pb-0 pt-4">
              <button
                type="button"
                className="btn app-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn blue-btn large-btn ">
                {loader && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2 location-spinner"
                  />
                )}
                Send
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
