import React from "react";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import { NavLink } from 'react-router-dom';
import TopNavBar from "../common-components/TopNavbar";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  // NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Form,
  Col,
} from "reactstrap";
export default function ComingSoon() {
  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">

          <div className="app-content">
          <TopNavBar />
            <div className="setting-page">
              <div className="white-container coming-soon">

                {/* <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      Coming Soon
                    </li>
                  </ol>
                </nav> */}
                <div className="w-100">
                    <h2>Coming Soon</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
