import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory, NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import NumberFormat from "react-number-format";
// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import AddLocationModel from "../models/AddLocationModel";
import * as inputFun from "../common-components/FormInputFunctions";
import Select from "react-select";
//images import
import DownArrowIcon from "../assets/images/icon-donarrow-green.svg";
import PrintIcon from "../assets/images/icon-print-green.svg";
import PaymentCardImg from "../assets/images/paymentcard-img.svg";
import WifiSignalImg from "../assets/images/wifi-signal-img.svg";
import VisaCardImg from "../assets/images/visa-card-img.svg";
import CvvImg from "../assets/images/cvv-img.svg";
import TopNavBar from "../common-components/TopNavbar";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  // NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Form,
  Col,
} from "reactstrap";
export default function PaymentMethodPage() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [expDate, setExpDate] = useState(new Date("02/02/2023"));
  const [CreditCard, SetCreditCard] = React.useState('Credit Card');
  let history = useHistory();
  const redirectToBilling = () => {
    history.push("/setting/billing");
  };
      const options = [
        { value: "Checking", label: "Checking" },
        { value: "Savings", label: "Savings" },
        { value: "Business Checking", label: "Business Checking" },
      ];
       const [selectedOption, setselectedOption] = useState(null);
      const handleChange = (selectedOption) => {
        setselectedOption(selectedOption);
      };

      const ACHoptions = [
        { value: "ARC", label: "ARC" },
        { value: "BOC", label: "BOC" },
        { value: "CCD", label: "CCD" },
        { value: "PPD", label: "PPD" },
        { value: "TEL", label: "TEL" },
        { value: "WEB", label: "WEB" },
      ];
       const [selectedACHOption, setselectedACHOption] = useState(null);
      const handleACHChange = (selectedACHOption) => {
        setselectedACHOption(selectedACHOption);
      };
  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsBilling={true} />
      <main>
        <div className="app-container">

          <div className="app-content">
          <TopNavBar />
            <div className="billing-page paymentmethod-page">
              <div className="white-container payment-config-card w-100">
              <div className="page-info">
                <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item " aria-current="page">
                      <Link exact to="/setting/billing">
                        Billings{" "}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {" "}
                      Payment Method
                    </li>
                  </ol>
                </nav>
              </div>
                <form>
                  <h4 className="section-title">Payment Method</h4>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="app-radio custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="paymentselect"
                          className="custom-control-input"
                          defaultChecked
                          value="Credit Card"
                          // defaultChecked={CreditCard}
                          onChange={e => {
                            SetCreditCard(e.target.value);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                        >
                          Credit Card
                        </label>
                      </div>
                      <div className="app-radio custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="paymentselect"
                          className="custom-control-input"
                          value="ACH"
                          onChange={e => {
                            SetCreditCard(e.target.value);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio2"
                        >
                          ACH
                        </label>
                      </div>
                      {/* <div className="app-radio custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio3"
                          name="paymentselect"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio3"
                        >
                          Paypal
                        </label>
                      </div> */}
                    </div>
                  </div>
                  <div className={`credit-card-info payment-method-info  ${(CreditCard == 'Credit Card') ? 'active': ''}`}>
                      <h4 className="section-title">Payment Information</h4>
                      <div className="row">
                    <div className="col-12">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">Name on Card</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue="John Doe"
                          onChange={inputFun.checkInputHasValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">Card Number</label>
                        {/* <input type="text" className="form-control" defaultValue="1234 5678 9123" maxLength="19"/> */}
                        <NumberFormat
                          format="#### #### #### ####"
                          className="form-control"
                          defaultValue="1234 5678 9123"
                          onChange={inputFun.checkInputHasValue}
                        />
                      </div>
                    </div>
                  </div>
                      <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">Exp Date</label>
                        <DatePicker
                          selected={expDate}
                          dateFormat="MM/yyyy"
                          onChange={date => setExpDate(date)}
                          className="form-control"
                          popperPlacement="top-start"
                          maxLength="8"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group app-ctrl cvv-input has-value">
                        <label className="required">CVV</label>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          defaultValue="308"
                          maxLength="3"
                          onChange={inputFun.checkInputHasValue}
                        />
                        <img
                          src={CvvImg}
                          alt="CVV Inage"
                          className="info-img"
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className={`credit-card-info payment-method-info  ${(CreditCard == 'ACH') ? 'active': ''}`}>
                      <h4 className="section-title">Payment Information</h4>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group app-ctrl has-value">
                            <label className="required">Name on Account</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="John Doe"
                              onChange={inputFun.checkInputHasValue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                    <div className="col-12">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">Account Number</label>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // defaultValue="Account Number"
                          // placeholder="Account Number"
                          // onChange={inputFun.checkInputHasValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">check number</label>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          // defaultValue="Account Number"
                          // placeholder="Check Number"
                          // onChange={inputFun.checkInputHasValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group app-ctrl has-value">
                        <label className="required">Routing Number</label>
                        <NumberFormat
                        // format="#### #### #### ####"
                          type="text"
                          className="form-control"
                          // placeholder="Routing Number"
                          // defaultValue="Routing Number"
                          onChange={inputFun.checkInputHasValue}
                        />
                      </div>
                    </div>
                  </div>
                      

                  </div>
                  <div className="btn-box d-flex align-item-top">
                    <button
                      className="app-btn lightgray-btn large-btn app-btn-clear back-btn mr-3"
                      type="button"
                      onClick={redirectToBilling}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 9 8"
                      >
                        <path
                          fill="#0a2753"
                          d="M4.183 7.814a.664.664 0 000-.898L2.037 4.635h6.366C8.733 4.635 9 4.35 9 4c0-.35-.267-.635-.597-.635H2.037l2.146-2.281a.664.664 0 000-.898.572.572 0 00-.844 0L.175 3.55a.656.656 0 00-.175.45c0 .167.063.329.175.448l3.164 3.365c.233.248.61.248.844 0z"
                        />
                      </svg>
                      Back
                    </button>
                    <button
                      className="app-btn large-btn d-block text-uppercase save-btn"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AddLocationModel
        show={AddLocationModelShow}
        onHide={() => SetAddLocationModel(false)}
      />
    </div>
  );
}
