import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";

//component import
import * as inputFun from "../common-components/FormInputFunctions";
import { validateForm } from "../config/commonFun";
import Api from "../API/team-members";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";
import { validEmailRegex, validCharRegex, validPhoneRegex, validPasswordRegex, validZipCodeRegex } from '../config/appRegex';

export default function EditMemberModal(props) {
  const [loader, setLoader] = useState(false);

  const [option, setOption] = useState({
    id: 0,
    name: "",
  });
 const [addresses, setAddresses] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    if (user.UserId != 0) {
      getAddress(user.UserId);
       getSpecialities();
    }
  }, [user.UserId]);

  const getAddress = (id) => {
    if (id > 0) {
      Api.getAddress(id)
        .then((res) => {
          let result = res.data.Result;
          if (result.length > 0) {
            result.sort((a, b) => b.ContactType - a.ContactType);
          }
          setAddresses(result);
        })
        .catch((error) => {});
    }
  };
  const [specialty, setSpecialty] = useState([
    {
      Id: "",
      Description: "Select Specilty",
    },
  ]);

  const [selectedOption, setSelectedOption] = useState({
    id: 0,
    name: "",
  });

  const [userModel, setUserModel] = useState({
    TeamMemberId: 0,
    FirstName: "",
    LastName: "",
    Email: "",
    Specialty: "",
    Location: "",
    ProviderId: "",
    Specialtylist: [],
    Locationlist: [],
    provtype: false,
    FullName: null,
    CompanyId: 0,
    AccessOfDelete: false,
    ProviderType: 0,
  });

  const [errors, SetErrors] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Specialty: "",
    Location: "",
    ProviderType: "",
  });

  const [selectedSpecialty, setSelectedSpecialty] = useState({
    Id: 0,
    Name: "",
  });

  const onSpeSelect = (obj, newObj) => {
    SetErrors((errors) => ({
      ...errors,
      ["Specialty"]: "",
    }));
    setUserModel((userModel) => ({
      ...userModel,
      ["Specialty"]: obj.map((a) => a.id),
    }));
  };

  const onSpeRemove = (obj, reobj) => {
    if (obj.length == 0) {
      SetErrors((errors) => ({
        ...errors,
        ["Specialty"]: "Specialty is required.",
      }));
    }
    setUserModel((userModel) => ({
      ...userModel,
      ["Specialty"]: obj.map((a) => a.id),
    }));
  };

  const getSelectedSpecialty = (id) => {
    if (id > 0) {
      Api.getSelectedSpecialty(id)
        .then((res) => {
          setSelectedSpecialty(res.data.Result);
        })
        .catch((error) => {});
    }
  };

  const onInputChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    switch (name) {
      case "LastName":
        if (value.length == 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = "";
        }
        break;
      case "FirstName":
        if (value.length == 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = "";
        }
        break;
      case "Email":
          if (value.length == 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
       
      case "Location":
        if (value.length == 0) {
          errorValue = "Location is required.";
        } else {
          errorValue = "";
        }
        break;
      case "provtype":
        if (value.length == 0) {
          errorValue = "Type is required.";
        } else {
          errorValue = "";
        }
        break;
      // case "Specialty":
      //   if (value.length == 0) {
      //     errorValue = "Specialty is required.";
      //   } else {
      //     errorValue = "";
      //   }
      //   break;
      default:
        break;
    }

    SetErrors((error) => ({
      ...error,
      [name]: errorValue,
    }));
  };

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((res) => {
        setSpecialty(res.data.Result);
      })
      .catch((error) => {});
  };

  const onEdtitMemberFormSubmit = (event) => {
    let flagError = false;
    event.persist();
    event.preventDefault();
    if (userModel.LastName.length == 0) {
      SetErrors((error) => ({
        ...error,
        LastName: "Last Name is required.",
      }));
      flagError = true;
    }
    if (userModel.FirstName.length == 0) {
      SetErrors((error) => ({
        ...error,
        FirstName: "First Name is required.",
      }));
      flagError = true;
    }
    if (userModel.Email.length == 0) {
      SetErrors((error) => ({
        ...error,
        Email: "Email is required.",
      }));
      flagError = true;
    }else if(!validEmailRegex.test(userModel.Email)) {
      SetErrors((error) => ({
        ...error,
        Email: "Enter valid Email.",
      }));
      flagError = true;
    }

    if (userModel.Location.length == 0) {
      SetErrors((error) => ({
        ...error,
        Location: "Location is required.",
      }));
      flagError = true;
    }
    if (userModel.provtype.length == 0) {
      SetErrors((error) => ({
        ...error,
        ProviderType: "Type is required.",
      }));
      flagError = true;
    }
    if (userModel.Specialty.length == 0) {
      SetErrors((error) => ({
        ...error,
        Specialty: "Specialty is required.",
      }));
      flagError = true;
    }
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      let payload = { ...userModel };
      if (Array.isArray(payload.Specialty)) {
        payload.Specialty = payload.Specialty.toString();
      }
      Api.addEditTeamMemer(payload)
        .then((res) => {
          setLoader(false);
          toast.success("Updated successfully!");
          props.onHide(true);
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong");
        });
    }
  };

  const getTeamMemberDetails = (id) => {
    setLoader(true);
    Api.getTeamMemberDetails(id)
      .then((resp) => {
        setLoader(false);
        setUserModel(resp.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (props.memeberid) {
      getTeamMemberDetails(props.memeberid);
     
      getSelectedSpecialty(props.memeberid);
    } else {
      setUserModel({
        TeamMemberId: 0,
        FirstName: "",
        LastName: "",
        Email: "",
        Specialty: "",
        Location: "",
        ProviderId: "",
        Specialtylist: [],
        Locationlist: [],
        provtype: false,
        FullName: null,
        CompanyId: 0,
        AccessOfDelete: false,
        ProviderType: 0,
      });
      SetErrors({
        FirstName: "",
        LastName: "",
        Email: "",
        Specialty: "",
        Location: "",
        ProviderType: "",
      });
    }
  }, [props.memeberid]);

  useEffect(() => {
    let selOps = [];

    for (let i = 0; i < selectedSpecialty.length; i++) {
      let obj = { id: 0, name: "" };
      obj.id = selectedSpecialty[i].Id;
      obj.name = selectedSpecialty[i].Name;
      selOps.push(obj);
    }
   
    setSelectedOption(selOps);
    setUserModel((userData) => ({
      ...userData,
      ["Specialty"]: selOps.map((a) => a.id),
    }));
  }, [selectedSpecialty.length]);

  useEffect(() => {
    let ops = [];
    for (let i = 0; i < specialty.length; i++) {
      let obj = { id: 0, name: "" };
      obj.id = specialty[i].Id;
      obj.name = specialty[i].Description;
      ops.push(obj);
    }
    setOption(ops);
  }, [specialty.length]);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Update Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body position-relative">
          <form onSubmit={onEdtitMemberFormSubmit}>
            <div className="row ">
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl">
                  <label className="required">Last Name </label>
                  <input
                    type="text"
                    className="form-control"
                    name="LastName"
                    value={userModel.LastName}
                    onChange={onInputChange}
                  />
                  <div className="err-message">{errors.LastName}</div>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl">
                  <label className="required">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="FirstName"
                    value={userModel.FirstName}
                    onChange={onInputChange}
                  />
                  <div className="err-message">{errors.FirstName}</div>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl">
                  <label className="required">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Email"
                    value={userModel.Email}
                    onChange={onInputChange}
                    disabled ={props.memeberid>0}
                  />
                  <div className="err-message">{errors.Email}</div>
                </div>
              </div>

              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">Location</label>
                  <select
                    className="form-control"
                    name="Location"
                    value={userModel.Location}
                    onChange={onInputChange}
                  >
                    {userModel.Locationlist.length ?
                     ( userModel.Locationlist.map((l) => (
                        <option value={l.Value}>{l.Text}</option>
                      ))) :(

(addresses.length &&  addresses.map((l) => (
                        <option value={l.Id}>{l.Name}</option>
                      )) )
                      )
                    }
                  </select>
                  <div className="err-message">{errors.Location}</div>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">Type</label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="provtype"
                      value={userModel.provtype}
                      onChange={onInputChange}
                    >
                      <option value="true">Staff</option>
                      <option value="false">Team Member</option>
                    </select>
                    <div className="err-message">{errors.ProviderType}</div>
                  </div>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl">
                  <label className="required">Speciality</label>
                  <div className="form-group">
                    {/* <select
                      className="form-control"
                      name="Specialty"
                      value={userModel.Specialty}
                      onChange={onInputChange}
                    >
                      <option value="">Select Specilty</option>
                      {specialty.length &&
                        specialty.map((s) => (
                          <option value={s.Id}>{s.Description}</option>
                        ))}
                    </select> */}
                    <Multiselect
                      className="multi-select"
                      options={option} // Options to display in the dropdown
                      showCheckbox={true}
                      placeholder="Select Dental Specialty"
                      displayValue="name"
                      closeOnSelect={false}
                      onSelect={onSpeSelect}
                      onRemove={onSpeRemove}
                      selectedValues={selectedOption}
                    />
                    <div className="err-message">{errors.Specialty}</div>
                  </div>
                  {/* <Multiselect
                    className="multi-select"
                    options={option} // Options to display in the dropdown
                    showCheckbox={true}
                    placeholder="Select Specialty"
                    displayValue="name"
                    closeOnSelect={false}
                    selectedValues={selectedOption}
                    onSelect={onSpeSelect}
                    onRemove={onSpeRemove}
                  />
                  <span className="select-down-arrow">
                    <i
                      className="fa fa-chevron-down"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="err-message">
                    {errors.Specialty}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide(false)}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                Update
              </button>
            </div>
          </form>
          {loader && <AppPageLoader />}
        </Modal.Body>
      </Modal>
    </div>
  );
}
