import React, { useState, useEffect } from "react";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
// import { Link } from 'react-router-dom'
import TopNavBar from "../common-components/TopNavbar";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Form,
  Col,
  Spinner,
} from "reactstrap";
import { validateForm, goToSignin } from "../config/commonFun";
import Api from "../API/setting";
import AppPageLoader from "../common-components/AppPageLoader";
import { ToastContainer, toast } from "react-toastify";
import {
  validCharRegex,
  validEmailRegex,
  validPhoneRegex,
  validPasswordRegex,
  validZipCodeRegex,
} from "../config/appRegex";

export default function AccountSetting() {
  const [curPasswordShown, setCurPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  function eyeToggleNew(event) {
    setNewPasswordShown(newPasswordShown ? false : true);
  }
  function eyeToggleCur(event) {
    setCurPasswordShown(curPasswordShown ? false : true);
  }
  function eyeToggleCon(event) {
    setConPasswordShown(conPasswordShown ? false : true);
  }
  function toggleCssClass(element, eleClass) {
    element.classList.toggle(eleClass);
  }
  const [AddMemberModelShow, SetAddMemberModel] = React.useState(false);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const locationoptions = [
    { value: "Dummy Location Name", label: "Dummy Location Name" },
  ];

  const [selectedOption, setselectedOption] = useState(null);
  const [selectedlocationOption, setselectedlocationOption] = useState(null);

  const [activeTab, setActiveTab] = useState("1");
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [AddLocationModelEditShow, SetAddLocationEditModel] =
    React.useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChangeLocation = (selectedlocationOption) => {
    setselectedlocationOption(selectedlocationOption);
  };

  const [btnLoader, setBtnLoader] = useState(false);
  const [userPassword, setUserPassword] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  const [passErrors, setPassErrors] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  const [cPasswordRes, setCPasswordRes] = useState("");

  const [loaderForProfile, SetLoaderForProfile] = useState(false);

  const handelPasswordChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    let errorValue = "";
    setUserPassword((userPassword) => ({
      ...userPassword,
      [name]: value,
    }));
    switch (name) {
      case "CurrentPassword":
        if (value.trim().length == 0) {
          errorValue = "Old password is required.";
        }
        break;
      case "NewPassword":
        if (value.trim().length == 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = !validPasswordRegex.test(value)
            ? "Password must be a minimum of 8 characters long and contain an upper and lower case letter, a number, and a symbol. "
            : "";
        }
        break;
      case "ConfirmPassword":
        if (value.trim().length == 0) {
          errorValue = "Confirm Password is required.";
        } else {
          errorValue =
            userPassword.NewPassword != value
              ? "Confirm password doesn't match with password"
              : "";
        }
        break;
      default:
        break;
    }
    setPassErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelCPasswordSubmit = (event) => {
    setCPasswordRes("");
    let flagError = false;
    if (userPassword.CurrentPassword.trim().length == 0) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        ["CurrentPassword"]: "Old password is required.",
      }));
    }
    if (userPassword.NewPassword.trim().length == 0) {
      flagError = true;

      setPassErrors((errors) => ({
        ...errors,
        ["NewPassword"]: "Password is required.",
      }));
    } else if (!validPasswordRegex.test(userPassword.NewPassword)) {
      flagError = true;

      setPassErrors((errors) => ({
        ...errors,
        ["NewPassword"]:
          "Password must be a minimum of 8 characters long and contain an upper and lower case letter, a number, and a symbol.",
      }));
    }
    if (userPassword.ConfirmPassword.trim().length == 0) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        ["ConfirmPassword"]: "Confirm Password is required.",
      }));
    } else if (userPassword.NewPassword != userPassword.ConfirmPassword) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        ["ConfirmPassword"]: "Confirm password doesn't match with password.",
      }));
    }
    event.preventDefault();
    if (validateForm(passErrors) && !flagError) {
      setBtnLoader(true);
      Api.postChangePassword(userPassword)
        .then((res) => {
          setBtnLoader(false);
          toast.success(res.data.Message);
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          setTimeout(() => {
            goToSignin();
          }, 2000);
        })
        .catch((error) => {
          setBtnLoader(false);
          if (error.response) {
            toast.error(error.response.data.Message);
          }
        });
    }
  };

  const [localUserId, setLocalUserId] = useState();

  const [userInformation, setUserInformation] = useState({
    UserId: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  const [updatedUserInformation, setUpdatedUserInformation] = useState({
    UserId: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  const [userInformationError, setUserInformationError] = useState({
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userData"));
    if (userInfo != null && userInfo.UserId && userInfo.UserId > 0) {
      setLocalUserId(userInfo.UserId);
      setUserInformation((userInformation) => ({
        ...userInformation,
        UserId: userInfo.UserId,
      }));
    }
  }, [localUserId]);

  useEffect(() => {
    if (
      userInformation != null &&
      userInformation.UserId &&
      userInformation.UserId > 0
    ) {
      getUserDetails(userInformation.UserId);
    }
  }, [userInformation.UserId]);

  const resetInformation = () => {
    setUpdatedUserInformation({ ...userInformation });
  };

  const getUserDetails = (id) => {
    SetLoaderForProfile(true);
    Api.getUserDetails(id)
      .then((res) => {
        SetLoaderForProfile(false);
        if (res.status == 200) {
          setUserInformation(res.data.Result);
          setUpdatedUserInformation({ ...res.data.Result });
        }
      })
      .catch((errr) => {
        SetLoaderForProfile(false);
      });
  };

  const handelAccountSettingSubmit = (event) => {
    event.preventDefault();
    let errorFlag = false;
    if (updatedUserInformation.UserName.length == 0) {
      setUserInformationError((error) => ({
        ...error,
        UserName: "User Name is required.",
      }));
      errorFlag = true;
    }

    if (updatedUserInformation.FirstName.length == 0) {
      setUserInformationError((error) => ({
        ...error,
        FirstName: "First Name is required.",
      }));
      errorFlag = true;
    }
    if (updatedUserInformation.LastName.length == 0) {
      setUserInformationError((error) => ({
        ...error,
        LastName: "Last Name is required.",
      }));
      errorFlag = true;
    }
    if (updatedUserInformation.Email.length == 0) {
      setUserInformationError((error) => ({
        ...error,
        Email: "Primary Email is required.",
      }));
      errorFlag = true;
    }

    if (!validEmailRegex.test(updatedUserInformation.Email)) {
      setUserInformationError((error) => ({
        ...error,
        Email: "Enter valid primary email.",
      }));
      errorFlag = true;
    }

    if (
      updatedUserInformation.SecondaryEmail.length > 0 &&
      !validEmailRegex.test(updatedUserInformation.SecondaryEmail)
    ) {
      setUserInformationError((error) => ({
        ...error,
        SecondaryEmail: "Enter valid email.",
      }));
      errorFlag = true;
    }

    if (validateForm(userInformationError) && !errorFlag) {
      setBtnLoader(true);
      Api.postUserDetails(updatedUserInformation)
        .then((res) => {
          let user = JSON.parse(localStorage.getItem("userData"));
          user.FirstName = updatedUserInformation.FirstName;
          user.LastName = updatedUserInformation.LastName;
          user.Username = updatedUserInformation.UserName;
          user.Email = updatedUserInformation.Email;
          localStorage.setItem("userData", JSON.stringify(user));
          setBtnLoader(false);
          toast.success(res.data.Message);
        })
        .catch((error) => {
          setBtnLoader(false);
          if (error.response) {
            toast.error(error.response.data.Message);
          }
        });
    }
  };

  const handelAccountSettingChangeEvent = (event) => {
    event.persist();
    var { name, value } = event.target;
    value = value.trim();
    setUpdatedUserInformation((updatedUserInformation) => ({
      ...updatedUserInformation,
      [name]: value,
    }));

    var errorMsg = "";
    switch (name) {
      case "UserName":
        if (value.length == 0) {
          errorMsg = "User Name is required.";
        }
        break;
      case "FirstName":
        if (value.length == 0) {
          errorMsg = "First Name is required.";
        }
        break;
      case "LastName":
        if (value.length == 0) {
          errorMsg = "Last Name is required.";
        }
        break;
      case "Email":
        if (value.length == 0) {
          errorMsg = "Primary Email is required.";
        } else if (!validEmailRegex.test(value)) {
          errorMsg = "Enter valid primary email.";
        }
        break;
      case "SecondaryEmail":
        if (value.length > 0 && !validEmailRegex.test(value)) {
          errorMsg = "Enter valid email.";
        }
        break;
      default:
        break;
    }

    setUserInformationError((userInformationError) => ({
      ...userInformationError,
      [name]: errorMsg,
    }));
  };
  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="setting-page">
              <div className="white-container coming-soon">
                {/* <div className="page-info">
                  <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active" aria-current="page">
                        Account Settings
                      </li>
                    </ol>
                  </nav>
                </div> */}
                <div className="w-100 setting-menu">
                  <Row>
                    <Col sm="12">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Account Setting
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Change Password
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Form
                            className="account-tab position-relative"
                            onSubmit={handelAccountSettingSubmit}
                          >
                            <div className="step-block">
                              <Row>
                                <Col md={12} xl={12}>
                                  <div className="form-group app-ctrl">
                                    <label class="required">User Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="User Name*"
                                      name="UserName"
                                      maxLength="100"
                                      value={updatedUserInformation.UserName}
                                      onChange={handelAccountSettingChangeEvent}
                                    />
                                    <div className="err-message">
                                      {userInformationError.UserName}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label class="required">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="First Name*"
                                      name="FirstName"
                                      value={updatedUserInformation.FirstName}
                                      onChange={handelAccountSettingChangeEvent}
                                    />
                                    <div className="err-message">
                                      {userInformationError.FirstName}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label className="d-none d-xl-block">
                                      &nbsp;
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Last Name*"
                                      name="LastName"
                                      value={updatedUserInformation.LastName}
                                      onChange={handelAccountSettingChangeEvent}
                                    />
                                    <div className="err-message">
                                      {userInformationError.LastName}
                                    </div>
                                  </div>
                                </Col>

                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label class="required">
                                      Primary Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Email*"
                                      name="Email"
                                      maxLength="100"
                                      value={updatedUserInformation.Email}
                                      onChange={handelAccountSettingChangeEvent}
                                    />
                                    <div className="err-message">
                                      {userInformationError.Email}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label>Secondary Email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Secondary Email"
                                      name="SecondaryEmail"
                                      maxLength="100"
                                      value={
                                        updatedUserInformation.SecondaryEmail
                                      }
                                      onChange={handelAccountSettingChangeEvent}
                                    />
                                    <div className="err-message">
                                      {userInformationError.SecondaryEmail}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12}>
                                <div class="btn-box d-flex ">
                                  <button
                                    type="button"
                                    class="btn app-btn lightgray-btn large-btn mr-2"
                                    onClick={resetInformation}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className={`btn app-btn large-btn ${
                                      btnLoader ? "btn-loading-state" : ""
                                    }`}
                                  >
                                    <div className="button-inner">
                                      {btnLoader && (
                                        <Spinner
                                          style={{ verticalAlign: "middle" }}
                                        />
                                      )}
                                      <span className="btn-text">Update</span>
                                    </div>
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            {loaderForProfile && <AppPageLoader />}
                          </Form>
                        </TabPane>
                        <TabPane tabId="2">
                          <Form
                            className="password-tab"
                            onSubmit={handelCPasswordSubmit}
                          >
                            <div className="step-block">
                              <Row>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Current Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={
                                          curPasswordShown ? "text" : "password"
                                        }
                                        className="form-control"
                                        defaultValue=""
                                        placeholder="Enter old password"
                                        onChange={handelPasswordChange}
                                        value={userPassword.CurrentPassword}
                                        name="CurrentPassword"
                                      />
                                      <span className="input-icon">
                                        <i
                                          className={`passTogEye icon-eye ${
                                            curPasswordShown
                                              ? "passVisible"
                                              : ""
                                          }`}
                                          onClick={eyeToggleCur}
                                        ></i>
                                      </span>
                                      <div className="err-message pl-0 pt-0">
                                        {passErrors.CurrentPassword}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={
                                          newPasswordShown ? "text" : "password"
                                        }
                                        className="form-control"
                                        placeholder="Enter new password"
                                        onChange={handelPasswordChange}
                                        value={userPassword.NewPassword}
                                        name="NewPassword"
                                      />
                                      <span className="input-icon">
                                        <i
                                          className={`passTogEye icon-eye ${
                                            newPasswordShown
                                              ? "passVisible"
                                              : ""
                                          }`}
                                          onClick={eyeToggleNew}
                                        ></i>
                                      </span>
                                      <div className="err-message pl-0 pt-0">
                                        {passErrors.NewPassword}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} xl={6}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Confirm New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={
                                          conPasswordShown ? "text" : "password"
                                        }
                                        className="form-control"
                                        placeholder="Enter confirm password"
                                        onChange={handelPasswordChange}
                                        value={userPassword.ConfirmPassword}
                                        name="ConfirmPassword"
                                      />
                                      <span className="input-icon">
                                        <i
                                          className={`passTogEye icon-eye ${
                                            conPasswordShown
                                              ? "passVisible"
                                              : ""
                                          }`}
                                          onClick={eyeToggleCon}
                                        ></i>
                                      </span>
                                      <div className="err-message pl-0 pt-0">
                                        {passErrors.ConfirmPassword}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12} xl={6}>
                                <div class="btn-box d-flex ">
                                  <button
                                    type="submit"
                                    className={`btn app-btn large-btn ${
                                      btnLoader ? "btn-loading-state" : ""
                                    }`}
                                  >
                                    <div className="button-inner">
                                      {btnLoader && (
                                        <Spinner
                                          style={{ verticalAlign: "middle" }}
                                        />
                                      )}
                                      <span className="btn-text">Update</span>
                                    </div>
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                      </TabContent>
                      {/* <Form className="account-tab">
                            <div className="step-block">
                              <Row>
                              <Col md={12} xl={8}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      User Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="1234 5678 9123"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Primary Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Johndoe@gmail.com"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Secondary Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Johndoe123@gmail.com"
                                    />
                                  </div>
                                </Col>
                                
                               
                              </Row>
                              <Row>
                                
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={newPasswordShown ? "text" : "password"}
                                        className="form-control"
                                        defaultValue="12345678"
                                      />
                                      <i className="input-icon pass-icon-eye eyeToggle fa fa-eye fa-eye-slash" onClick={eyeToggleNew}></i>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Confirm New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={conPasswordShown ? "text" : "password"}
                                        className="form-control"
                                        defaultValue="12345678"
                                      />
                                      <i className="input-icon pass-icon-eye eyeToggle fa fa-eye fa-eye-slash" onClick={eyeToggleCon}></i>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12}>
                                <div class="btn-box d-flex ">
                                  <button
                                    type="button"
                                    class="btn app-btn lightgray-btn large-btn mr-2"
                                  >
                                    cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn app-btn large-btn "
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
