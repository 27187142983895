import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

let from = "TestUser";

// Main Api Class for login functionality
class Api {
  // API for get getCompanyId
  static getCompanyId = (url) => {
    return axios.get(API_URL + "/Verident/CompanyId?strDomain=" + url);
  };

  // API for user Login
  static logIn = (payload) => {
    let UserModel = {
      Username: payload.Username,
      Password: payload.Password,
      CompanyId: localStorage.getItem("CompanyId"),
    };
    return axios.post(API_URL + "/Verident/Login", UserModel, HEADER);
  };

  // API for forgot password
  static forgotPassword = (payload) => {
    return axios.post(
      API_URL + "/Verident/ForgotPassowrd?Email=" + payload + "&From=" + from,
      "",
      HEADER
    );
  };

  // API for Reset password
  static resetPassword = (payload) => {
    let model = {
      Email: payload.Email,
      Password: payload.Password,
    };
    return axios.post(API_URL + "/Verident/ResetPassword", model, HEADER);
  };

  // Account activation API
  static accountActivation = (email) => {
    return axios.get(
      API_URL + "/Verident/AccountActivation?Email=" + email,
      ""
    );
  };

  static authentication = (data, header) => {
    return axios.post(API_URL + "/SingleSignOn/Authentication", data, header);
  };

  
}
export default Api;
