import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../API/profile";
import { ToastContainer, toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";

//component import
import * as inputFun from "../common-components/FormInputFunctions";

export default function AddProcedureDetailModel(props) {
  const [modal, setModal] = useState(false);
  const [procedure, setProcedure] = useState({
    Discount: 0,
    InsuranceCostRatio: 0,
    IsEnable: false,
    ProcedureId: 0,
    ProcedureName: "",
    StandardRate: 0,
    UserId: 0,
  });

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (props.UserId > 0) {
      Api.getProcedure()
        .then((res) => {
          setProcedure(res.data.Result);
        })
        .catch((error) => {});
    }
  }, [props.UserId]);

  const handelChange = (event, i, int) => {
    let obj = [...procedure];
    if (int == 1) {
      obj[i].IsEnable = event.target.checked;
    } else if (int == 2) {
      obj[i].InsuranceCostRatio = event.target.value;
    } else if (int == 3) {
      obj[i].StandardRate = event.target.value;
    }
    setProcedure(obj);
  };

  // const handelInsuranceCostRatio = (event, i) => {
  //   let obj = [...procedure];
  //   obj[i].InsuranceCostRatio = event.target.value;
  //   console.log(obj);
  //   setProcedure(obj);
  // };

  // const handelStandardRate = (event, i) => {
  //   let obj = [...procedure];
  //   obj[i].StandardRate = event.target.value;
  //   console.log(obj);
  //   setProcedure(obj);
  // };
  const [loader, setLoader] = useState(false);
  const handelSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    Api.postProcedure(procedure)
      .then((res) => {
        setLoader(false);
        props.onHide();
        toast.success("Procedure Detail updated successfully");
        setTimeout(() => {
          props.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.data.StatusCode == 400) {
          toast.warn(error.response.data.Result);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model procedure-detail-page"
        id="drpro-select"
      >
        <Modal.Header closeButton className="title-with-sub">
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6 "
          >
            Edit Procedure Detail
            <span>
              Disclaimer: This section is for the Estimate Cost of Insurance
              tool only.
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 ">
                <div className="app-table procedure-detail-table">
                  <div className="table-responsive">
                    <table className="table table-hover table-borderless invoice-grid">
                      <thead>
                        <tr>
                          <th className="chk-col">
                            <div className="custom-control custom-checkbox app-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="chkall"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="chkall"
                              ></label>
                            </div>
                          </th>
                          <th>Procedures Name</th>

                          <th>Insurance Cost Ratio</th>
                          <th>Standard Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {procedure != null &&
                          procedure.length > 0 &&
                          procedure.map((pro, i) => (
                            <tr key={pro.ProcedureId} className="chk-col">
                              <td>
                                <div className="custom-control custom-checkbox app-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={pro.IsEnable}
                                    id={pro.ProcedureId}
                                    onChange={(e) => handelChange(e, i, 1)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={pro.ProcedureId}
                                  ></label>
                                </div>
                              </td>
                              <td>{pro.ProcedureName}</td>
                              <td>
                                <div class="form-group app-ctrl has-value">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="0.00"
                                    value={pro.InsuranceCostRatio}
                                    onChange={(e) => handelChange(e, i, 2)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-group app-ctrl has-value">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="0.00"
                                    value={pro.StandardRate}
                                    onChange={(e) => handelChange(e, i, 3)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}{" "}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">Update</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
