import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import ListGroup from "react-bootstrap/ListGroup";
import Api from "../API/profile";
import { RecordLincURL } from "../config/appConstatnts";
import Avtar from "../assets/images/Doctor_no_image.gif";
// component imports
import uploadIcon from "../assets/images/upload-img.svg";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Banner_1 from "../assets/images/dentalImg1.jpg";
import Banner_2 from "../assets/images/dentalImg2.jpg";
import Banner_3 from "../assets/images/dentalImg3.webp";
import Banner_4 from "../assets/images/dentalImg4.jpg";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import AddLocationProfileModel from "../models/AddLocationProfileModel";
import EditLocationProfileModel from "../models/EditLocationProfileModel";
import EditUserProfileModel from "../models/EditUserProfileModel";
import AddBannerModal from "../models/AddBanner";
import AddImageGalleryModal from "../models/AddImageGallery";
import ProfileSectionModal from "../models/ProfileSection";
import AddEduTrainingInfoPopup from "../models/AddEduTrainingInfoPopup";
import EditEduTrainingInfoPopup from "../models/EditEduTrainingInfoPopup";
import WidgetMenuModal from "../models/WidgetMenu";
import AddWebsiteModal from "../models/AddWebsiteModal";
import { ToastContainer, toast } from "react-toastify";
import parse from "html-react-parser";

import EditWebsiteModal from "../models/EditWebsiteModal";
import SocialMediaModal from "../models/SocialMediaModal";
import AddDescriptionModal from "../models/AddDescriptionModal";
import AddEducationInfomationModel from "../models/AddEducationInformation";
import AddMembershipModal from "../models/AddMembershipModal";
import AddProfileImageModal from "../models/AddProfileImage";
import AddInsuranceDetailModal from "../models/AddInsuranceDetailModal";
import AddProcedureDetailModel from "../models/AddProcedureDetailModal";
import AddProfessionalMembershipModal from "../models/AddProfessionalMembershipModal";
import EditProfessionalMembershipModal from "../models/EditProfessionalMembershipModal";
import AddInsuranceDetailsModal from "../models/AddInsuranceDetailsModal";
import EditSocialMediaModel from "../models/EditSocialMediaModel";
import { Table } from "reactstrap";
import AppPageLoader from "../common-components/AppPageLoader";

import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
//images import
import userThumb from "../assets/images/doctor-image.png";

//user-thumb-1.png
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  // NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
export default function ProfilePage() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [EditLocationModelShow, SetEditLocationModel] = React.useState(false);
  const [EditProfileModelShow, SetEditProfileModel] = React.useState(false);
  const [AddBannerModalShow, SetAddBannerModal] = React.useState(false);
  const [AddImageGalleryModalShow, SetAddImageGalleryModal] =
    React.useState(false);
  const [ProfileSectionModalShow, SetProfileSectionModal] =
    React.useState(false);
  const [WidgetMenuModalShow, SetWidgetMenuModal] = React.useState(false);
  const [AddWebsiteModalShow, SetAddWebsiteModal] = React.useState(false);
  const [EditWebsiteModalShow, SetEditWebsiteModal] = React.useState(false);
  const [SocialMediaModalShow, SetSocialMediaModal] = React.useState(false);
  const [AddDescriptionModalShow, SetAddDescriptionModal] =
    React.useState(false);
  const [AddEducationInfomationModelShow, SetAddEducationInfomationModel] =
    React.useState(false);
  const [AddMembershipModalShow, SetAddMembershipModal] = React.useState(false);
  const [AddInsuranceDetailModalShow, SetAddInsuranceDetailModal] =
    React.useState(false);
  const [AddProcedureDetailModelShow, SetAddProcedureDetailModel] =
    React.useState(false);
  const [AddEduTrainingInfoPopupShow, SetAddEduTrainingInfoPopup] =
    React.useState(false);
  const [EditEduTrainingInfoPopupShow, SetEditEduTrainingInfoPopup] =
    React.useState(false);
  const [
    AddProfessionalMembershipModalShow,
    SetAddProfessionalMembershipModal,
  ] = React.useState(false);
  const [
    EditProfessionalMembershipModalShow,
    SetEditProfessionalMembershipModal,
  ] = React.useState(false);
  const [AddInsuranceDetailsModalShow, SetAddInsuranceDetailsModal] =
    React.useState(false);
  const [EditSocialMediaModelShow, SetEditSocialMediaModel] =
    React.useState(false);

  const [loaderForProfile, SetLoaderForProfile] = useState(true);
  // const [AddWebsiteModalShow, SetAddWebsiteModal] = React.useState(false);

  const [selectedEdu, SetSelectedEdu] = useState(0);
  const [SecondaryWebsiteId, SetSecondaryWebsiteId] = useState(0);
  const [AddProfileImageModalShow, SetAddProfileImageModal] =
    React.useState(false);
  const oneducationLink = (id) => {
    SetSelectedEdu(id);
    SetAddEducationInfomationModel(!AddEducationInfomationModelShow);
  };

  const onClickwebsite = (id) => {
    SetSecondaryWebsiteId(id);
    SetAddWebsiteModal(!AddWebsiteModalShow);
  };
  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const onClickSaveDes = (event) => {
    event.preventDefault();
    Api.updateDesciption(userDescription)
      .then((res) => {
        //   props.onModalClick();
        //toast.success("Description updated successfully!");
        // setTimeout(() => {
        //   reloadPage();
        // }, 1000);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
    // alert(userDescription);
  };

  const deleteWebSite = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteWebSiteDetail(id)
        .then((res) => {
          toast.success("Deleted successfully!");
          setTimeout(() => {
            reloadPage();
          }, 1000);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };
  const deleteMembership = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteMembership(id)
        .then((res) => {
          toast.success("Deleted successfully!");
          setTimeout(() => {
            reloadPage();
          }, 1000);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const deleteGallaryImage = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteGallaryImage(id)
        .then((res) => {
          //   props.onModalClick();
          toast.success("Deleted successfully!");
          setTimeout(() => {
            reloadPage();
          }, 1000);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const deleteBackgroundImage = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteBackgroundImage(id)
        .then((res) => {
          //   props.onModalClick();
          toast.success("Deleted successfully!");
          setTimeout(() => {
            reloadPage();
          }, 1000);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const handelDes = (event) => {
    let { name, value } = event.target;
    setUserDescription(value);
  };
  const [userDescription, setUserDescription] = useState({});

  const [userInfo, setUserInfo] = useState({
    UserId: 0,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Description: "",
    OfficeName: "",
    Title: "",
    ImageName: "",
    Email: "",
    Phone: "",
    City: "",
    State: "",
    ZipCode: "",
    DentrixProviderId: "",
    PublicProfile: "",
    WebsiteURL: "",
    PracticeName: "",
    TeamMemberUserId: 0,
    Location: "",
    IsTDO: false,
    ProfileComplete: 0,
    Profilepercentage: "",
    RemainList: "",
    Institute: "",
    MemberShip: "",
    EncryptUserId: "",
    LocationId: 0,
    Salutation: "",
    specialtyIds: "",
    GallaryPath: "",
    lstBanner: [],
    lstGallary: [],
    lstInsurance: [],
    lstDoctorAddressDetails: [],
    lstDoctorSortedAddressDetails: [],
    lstEducationandTraining: [],
    lstProfessionalMemberships: [],
    lstSpeacilitiesOfDoctor: [],
    lstProcedure: [],
    licensedetails: [],
    objLicense: {},
    ObjProfileSection: {},
    lstDoctorAddressDetailsByAddressInfoID: [],
    lstGetSocialMediaDetailByUserId: [],
    lstEducationandTrainingForDoctorById: [],
    lstProfessionalMembershipForDoctorById: [],
    lstsecondarywebsitelist: [],
    lstTimeZone: [],
    lstTeamMemberDetailsForDoctor: [],
    IsRewardPatner: false,
    isprofessional: false,
  });

  const getProfileDetails = (id) => {
    if (id > 0) {
      SetLoaderForProfile(true);
      Api.getProfile(id)
        .then((res) => {
          SetLoaderForProfile(false);
          setUserInfo(res.data);
        })
        .catch((error) => {
          SetLoaderForProfile(false);
        });
    }
  };

  const [profeMembership, setProfeMembership] = useState({
    Id: 0,
    Membership: "",
  });

  const ProfessionalMembership = (id, text) => {
    let obj = {
      Id: id,
      Membership: text,
    };
    setProfeMembership(obj);
    SetAddProfessionalMembershipModal(!AddProfessionalMembershipModalShow);
  };

  const reloadPage = () => {
    getProfileDetails(user.UserId);
  };

  // const  deleteGallaryImage = (id) => {
  //   return axios.post(
  //     API_URL + "/database/deleteGallaryImage?Id=" + id,
  //     "",
  //     HEADER
  //   );
  // };

  useEffect(() => {
    setUserDescription(userInfo.Description);
  }, [userInfo.UserId]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    if (user.UserId > 0) {
      getProfileDetails(user.UserId);
    }
  }, [user.UserId]);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="profile-page">
              <div className="white-container ">
                {/* <div className="page-info">
                  <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active" aria-current="page">
                        {" "}
                        Profile
                      </li>
                    </ol>
                  </nav>
                </div> */}
                <div className="d-flex">
                  <div className="user-information">
                    <div className="inner-container mb-4">
                      <div className="sec-header">
                        <h4 className="mr-auto">User Information</h4>
                        <button
                          className="app-btn app-btn-clear sec-action-btn "
                          onClick={() => SetEditProfileModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>{" "}
                          Edit
                        </button>
                      </div>
                      <div className="user-img-wrapper">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage:
                              "url(" + encodeURI(userInfo.ImageName) + ")",
                          }}
                          onClick={() => SetAddProfileImageModal(true)}
                        >
                          <div className="upload-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 36.174 36.174"
                            >
                              <path d="M23.921 20.528c0 3.217-2.617 5.834-5.834 5.834s-5.833-2.617-5.833-5.834 2.616-5.834 5.833-5.834 5.834 2.618 5.834 5.834zm12.253-8.284v16.57a4 4 0 01-4 4H4a4 4 0 01-4-4v-16.57a4 4 0 014-4h4.92V6.86a3.5 3.5 0 013.5-3.5h11.334a3.5 3.5 0 013.5 3.5v1.383h4.92c2.209.001 4 1.792 4 4.001zm-9.253 8.284c0-4.871-3.963-8.834-8.834-8.834-4.87 0-8.833 3.963-8.833 8.834s3.963 8.834 8.833 8.834c4.871 0 8.834-3.963 8.834-8.834z" />
                            </svg>
                          </div>
                        </div>
                        <h4 className="user-name">
                          {userInfo.Salutation +
                            " " +
                            userInfo.FirstName +
                            " " +
                            userInfo.LastName}
                        </h4>
                        <h4 className="user-prof">
                          {userInfo.lstSpeacilitiesOfDoctor &&
                            userInfo.lstSpeacilitiesOfDoctor.length > 0 &&
                            userInfo.lstSpeacilitiesOfDoctor.map(
                              (Speacilty, i, arr) => (
                                <span>
                                  {Speacilty.SpecialtyDescription}
                                  {i != arr.length - 1 ? ", " : ""}
                                </span>
                              )
                            )}
                        </h4>
                      </div>
                      <div className="user-basic-info">
                        <div className="info-row">
                          <span>First Name</span>
                          <label>{userInfo.FirstName} </label>
                        </div>
                        <div className="info-row">
                          <span>Last Name</span>
                          <label>{userInfo.LastName} </label>
                        </div>
                        <div className="info-row">
                          <span>Email</span>
                          <label> {userInfo.Email} </label>
                        </div>
                        <div className="info-row">
                          <span>Phone No</span>
                          <label>{userInfo.Phone}</label>
                        </div>
                        <div className="info-row">
                          <span>Practice Name</span>
                          <label>{userInfo.OfficeName}</label>
                        </div>
                        {/* <div className="info-row">
                          <span>No. of Operatories</span>
                          <label>04</label>
                        </div> */}
                      </div>
                    </div>
                    <div className="inner-container mb-4 education-training-box">
                      <div className="edu-section">
                        <div className="sec-header mb-2">
                          <h4 className="mr-auto  mb-0">
                            Education And Training
                          </h4>
                          <button
                            className="app-btn app-btn-clear sec-action-btn "
                            onClick={() => oneducationLink(0)}
                            // onClick={() => SetAddEducationInfomationModel(true)}
                          >
                            <i className="fa fa-plus"></i>
                            Add
                          </button>
                        </div>

                        <div className="user-basic-info ">
                          <div className="basic-info-scroll-wrapper mb-0">
                            {userInfo.lstEducationandTraining &&
                              userInfo.lstEducationandTraining.length > 0 &&
                              userInfo.lstEducationandTraining.map(
                                (edu, index) => (
                                  <div className="inner-card">
                                    <button
                                      className="app-btn app-btn-clear action-btn"
                                      onClick={() => oneducationLink(edu.Id)}
                                      // onClick={() =>
                                      //   SetAddEducationInfomationModel(true)
                                      // }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 17"
                                      >
                                        <path
                                          fill="#707070"
                                          d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                        />
                                      </svg>
                                    </button>
                                    <div className="info-row">
                                      <span>Institution Attended</span>
                                      <label>{edu.Institute}</label>
                                    </div>
                                    <div className="info-row">
                                      <span>Field of Study/Degree</span>
                                      <label> {edu.Specialisation} </label>
                                    </div>
                                    <div className="info-row">
                                      <span>Last Year Attended/ Graduated</span>
                                      <label> {edu.YearAttended} </label>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-container">
                      <div className="upload-img-sec">
                        <div className="sec-header ">
                          <h4 className="mr-auto">
                            Background Images{" "}
                            <span>
                              (Maximum 4 Background Images can be uploaded)
                            </span>
                          </h4>
                        </div>
                        <div className="image-upload-sec">
                          <div className="image-upload-inner">
                            <div
                              className="single-img upload-img"
                              style={{ backgroundImage: `url(${uploadIcon})` }}
                              onClick={() => SetAddBannerModal(true)}
                            >
                              {/* <Input type="file" name="file" id="exampleFile" /> */}
                            </div>
                            {userInfo.lstBanner &&
                              userInfo.lstBanner.length > 0 &&
                              userInfo.lstBanner.map((bann, index) => (
                                <div
                                  className="single-img"
                                  style={{
                                    backgroundImage: `url(${RecordLincURL}/Resources/Banner/${userInfo.UserId}/${bann.Path})`,
                                  }}
                                >
                                  <button
                                    onClick={() =>
                                      deleteBackgroundImage(bann.BannerId)
                                    }
                                    className="close-btn btn btn-secondary"
                                  ></button>
                                </div>
                              ))}

                            {/*  <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_2})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_3})` }}
                            ></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="advance-profile">
                    <div className="inner-container pr-4">
                      <div className="row">
                        <div className="col-xl-6 col-lg-12">
                          <div className="advance-list-profile full-width mb-5">
                            <div className="advance-profile-row">
                              <div className="sec-header ">
                                <h4 className="mr-auto">Description</h4>
                                {/* <button
                                  className="app-btn app-btn-clear save-btn sec-action-btn"
                                  onClick={onClickSaveDes}
                                >
                                  <i className="fa fa-save"></i> Save
                                </button> */}
                              </div>
                              {/* {parse(" <h1>single</h1>")} */}
                              <div className="profile-form-group">
                                <div className="form-group app-ctrl has-value  text-area-group">
                                  {/* <label className="">Website Url</label> */}
                                  <Input
                                    type="textarea"
                                    name="text"
                                    id="exampleText"
                                    onChange={handelDes}
                                    onBlur={onClickSaveDes}
                                    // value={parse(" <h1>single</h1>")}
                                    value={parse(
                                      userDescription != null &&
                                        userDescription.length > 0
                                        ? userDescription.replace(
                                            /<br\/>/gi,
                                            "\n"
                                          )
                                        : ""
                                    )}
                                  />
                                  {/* <input type="text" className="form-control" defaultValue="Lorem ispum dummy text " /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                          <div className="edu-section">
                            <div className="sec-header mb-0">
                              <h4 className="mr-auto  mb-0">
                                Insurance Detail
                              </h4>
                              <button
                                className="app-btn app-btn-clear sec-action-btn"
                                onClick={() =>
                                  SetAddInsuranceDetailsModal(true)
                                }
                              >
                                {userInfo.lstInsurance &&
                                userInfo.lstInsurance.length > 0 ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 17"
                                    >
                                      <path
                                        fill="#707070"
                                        d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                      />
                                    </svg>{" "}
                                    Edit
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-plus"></i> Add
                                  </>
                                )}
                              </button>
                            </div>
                            <div className="user-basic-info pt-3">
                              <div className="basic-info-scroll-wrapper">
                                <ListGroup className="info-list">
                                  {userInfo.lstInsurance &&
                                    userInfo.lstInsurance.length > 0 &&
                                    userInfo.lstInsurance.map((ins, index) => (
                                      <ListGroup.Item>
                                        <p class="mb-0">{ins.Name}</p>
                                        {/* <button className="app-btn app-btn-clear action-btn">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="181.5 181.5 64 64"
                                          >
                                            <path d="M220.428 233.321V206.6a1.414 1.414 0 112.827 0v26.721a1.414 1.414 0 11-2.827 0zm-15.269 1.414c.781 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721c0 .781.633 1.414 1.414 1.414zm28.842-33.448v34.834c0 2.058-.756 3.991-2.074 5.379a6.968 6.968 0 01-5.052 2.185h-26.75a6.968 6.968 0 01-5.052-2.185c-1.318-1.388-2.074-3.321-2.074-5.379v-34.834a5.402 5.402 0 011.385-10.621h7.239v-1.767a5.556 5.556 0 015.598-5.585h12.555a5.556 5.556 0 015.599 5.585v1.767h7.239a5.4 5.4 0 015.354 4.702 5.398 5.398 0 01-3.967 5.919zm-29.549-10.621h18.097v-1.767a2.724 2.724 0 00-2.772-2.756h-12.555a2.72 2.72 0 00-2.771 2.756v1.767zm26.721 10.745h-35.346v34.71c0 2.659 1.88 4.736 4.298 4.736h26.75c2.417 0 4.298-2.077 4.298-4.736v-34.71zm3.986-5.372a2.544 2.544 0 00-2.544-2.544h-38.23a2.545 2.545 0 100 5.089h38.23a2.544 2.544 0 002.544-2.545zM213.5 234.735c.78 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721a1.415 1.415 0 001.414 1.414z" />
                                          </svg>
                                        </button> */}
                                      </ListGroup.Item>
                                    ))}
                                </ListGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-lg-12">
                          <div className="edu-section">
                            <div className="sec-header mb-0">
                              <h4 className="mr-auto  mb-0">
                                Professional Membership
                              </h4>
                              <button
                                className="app-btn app-btn-clear sec-action-btn "
                                onClick={() => ProfessionalMembership(0, "")}
                              >
                                {userInfo.lstProfessionalMemberships &&
                                userInfo.lstProfessionalMemberships.length >
                                  0 ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 17"
                                    >
                                      <path
                                        fill="#707070"
                                        d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                      />
                                    </svg>{" "}
                                    Edit{" "}
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-plus"></i> Add
                                  </>
                                )}
                              </button>
                            </div>
                            <div className="user-basic-info pt-3">
                              <div className="basic-info-scroll-wrapper">
                                <ListGroup className="info-list">
                                  {userInfo.lstProfessionalMemberships &&
                                    userInfo.lstProfessionalMemberships.length >
                                      0 &&
                                    userInfo.lstProfessionalMemberships.map(
                                      (pro, index) => (
                                        <ListGroup.Item>
                                          <p class="mb-0">{pro.Membership}</p>
                                          <button
                                            className="app-btn app-btn-clear action-btn edit-action-btn"
                                            onClick={() =>
                                              ProfessionalMembership(
                                                pro.Id,
                                                pro.Membership
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 16 17"
                                            >
                                              <path
                                                fill="#707070"
                                                d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                              />
                                            </svg>
                                          </button>
                                          <button
                                            className="app-btn app-btn-clear action-btn delete-action-btn"
                                            onClick={() =>
                                              deleteMembership(pro.Id)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="181.5 181.5 64 64"
                                            >
                                              <path d="M220.428 233.321V206.6a1.414 1.414 0 112.827 0v26.721a1.414 1.414 0 11-2.827 0zm-15.269 1.414c.781 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721c0 .781.633 1.414 1.414 1.414zm28.842-33.448v34.834c0 2.058-.756 3.991-2.074 5.379a6.968 6.968 0 01-5.052 2.185h-26.75a6.968 6.968 0 01-5.052-2.185c-1.318-1.388-2.074-3.321-2.074-5.379v-34.834a5.402 5.402 0 011.385-10.621h7.239v-1.767a5.556 5.556 0 015.598-5.585h12.555a5.556 5.556 0 015.599 5.585v1.767h7.239a5.4 5.4 0 015.354 4.702 5.398 5.398 0 01-3.967 5.919zm-29.549-10.621h18.097v-1.767a2.724 2.724 0 00-2.772-2.756h-12.555a2.72 2.72 0 00-2.771 2.756v1.767zm26.721 10.745h-35.346v34.71c0 2.659 1.88 4.736 4.298 4.736h26.75c2.417 0 4.298-2.077 4.298-4.736v-34.71zm3.986-5.372a2.544 2.544 0 00-2.544-2.544h-38.23a2.545 2.545 0 100 5.089h38.23a2.544 2.544 0 002.544-2.545zM213.5 234.735c.78 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721a1.415 1.415 0 001.414 1.414z" />
                                            </svg>
                                          </button>
                                        </ListGroup.Item>
                                      )
                                    )}
                                </ListGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                          <div className="edu-section">
                            <div className="sec-header mb-0">
                              <h4 className="mr-auto  mb-0">Website</h4>
                              <button
                                className="app-btn app-btn-clear sec-action-btn "
                                onClick={() => onClickwebsite(0)}
                              >
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                            <div className="user-basic-info pt-3">
                              <div className="basic-info-scroll-wrapper">
                                <ListGroup className="info-list">
                                  {userInfo.lstsecondarywebsitelist &&
                                    userInfo.lstsecondarywebsitelist.length >
                                      0 &&
                                    userInfo.lstsecondarywebsitelist.map(
                                      (web, index) => (
                                        <ListGroup.Item>
                                          <a
                                            href={web.SecondaryWebsiteurl}
                                            target="_blank"
                                          >
                                            {web.SecondaryWebsiteurl}
                                          </a>
                                          <button
                                            className="app-btn app-btn-clear action-btn edit-action-btn"
                                            onClick={() =>
                                              onClickwebsite(
                                                web.SecondaryWebsiteId
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 16 17"
                                            >
                                              <path
                                                fill="#707070"
                                                d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                              />
                                            </svg>
                                          </button>
                                          <button
                                            className="app-btn app-btn-clear action-btn delete-action-btn"
                                            onClick={() =>
                                              deleteWebSite(
                                                web.SecondaryWebsiteId
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="181.5 181.5 64 64"
                                            >
                                              <path d="M220.428 233.321V206.6a1.414 1.414 0 112.827 0v26.721a1.414 1.414 0 11-2.827 0zm-15.269 1.414c.781 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721c0 .781.633 1.414 1.414 1.414zm28.842-33.448v34.834c0 2.058-.756 3.991-2.074 5.379a6.968 6.968 0 01-5.052 2.185h-26.75a6.968 6.968 0 01-5.052-2.185c-1.318-1.388-2.074-3.321-2.074-5.379v-34.834a5.402 5.402 0 011.385-10.621h7.239v-1.767a5.556 5.556 0 015.598-5.585h12.555a5.556 5.556 0 015.599 5.585v1.767h7.239a5.4 5.4 0 015.354 4.702 5.398 5.398 0 01-3.967 5.919zm-29.549-10.621h18.097v-1.767a2.724 2.724 0 00-2.772-2.756h-12.555a2.72 2.72 0 00-2.771 2.756v1.767zm26.721 10.745h-35.346v34.71c0 2.659 1.88 4.736 4.298 4.736h26.75c2.417 0 4.298-2.077 4.298-4.736v-34.71zm3.986-5.372a2.544 2.544 0 00-2.544-2.544h-38.23a2.545 2.545 0 100 5.089h38.23a2.544 2.544 0 002.544-2.545zM213.5 234.735c.78 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721a1.415 1.415 0 001.414 1.414z" />
                                            </svg>
                                          </button>
                                        </ListGroup.Item>
                                      )
                                    )}
                                </ListGroup>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-12">
                          <div className="edu-section">
                            <div className="sec-header mb-0">
                              <h4 className="mr-auto  ">Procedure List</h4>
                              <button
                                className="app-btn app-btn-clear sec-action-btn "
                                onClick={() => SetAddProcedureDetailModel(true)}
                              >
                                {userInfo.lstProcedure &&
                                userInfo.lstProcedure.length > 0 ? (
                                  <>
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 17"
                                    >
                                      <path
                                        fill="#707070"
                                        d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                      />
                                    </svg>{" "}
                                    Edit
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-plus"></i> Add
                                  </>
                                )}
                              </button>
                            </div>
                            <div className="user-basic-info pt-3">
                              <div className="basic-info-scroll-wrapper">
                                <div className="info-list-outer-multicol">
                                  <ListGroup className="info-list with-3-col">
                                    {userInfo.lstProcedure &&
                                      userInfo.lstProcedure.length > 0 &&
                                      userInfo.lstProcedure.map(
                                        (proc, index) => (
                                          <ListGroup.Item>
                                            <p className="mb-0">
                                              {proc.ProcedureName}
                                            </p>
                                            {/* <button className="app-btn app-btn-clear action-btn">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="181.5 181.5 64 64"
                                              >
                                                <path d="M220.428 233.321V206.6a1.414 1.414 0 112.827 0v26.721a1.414 1.414 0 11-2.827 0zm-15.269 1.414c.781 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721c0 .781.633 1.414 1.414 1.414zm28.842-33.448v34.834c0 2.058-.756 3.991-2.074 5.379a6.968 6.968 0 01-5.052 2.185h-26.75a6.968 6.968 0 01-5.052-2.185c-1.318-1.388-2.074-3.321-2.074-5.379v-34.834a5.402 5.402 0 011.385-10.621h7.239v-1.767a5.556 5.556 0 015.598-5.585h12.555a5.556 5.556 0 015.599 5.585v1.767h7.239a5.4 5.4 0 015.354 4.702 5.398 5.398 0 01-3.967 5.919zm-29.549-10.621h18.097v-1.767a2.724 2.724 0 00-2.772-2.756h-12.555a2.72 2.72 0 00-2.771 2.756v1.767zm26.721 10.745h-35.346v34.71c0 2.659 1.88 4.736 4.298 4.736h26.75c2.417 0 4.298-2.077 4.298-4.736v-34.71zm3.986-5.372a2.544 2.544 0 00-2.544-2.544h-38.23a2.545 2.545 0 100 5.089h38.23a2.544 2.544 0 002.544-2.545zM213.5 234.735c.78 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721a1.415 1.415 0 001.414 1.414z" />
                                              </svg>
                                            </button> */}
                                          </ListGroup.Item>
                                        )
                                      )}
                                  </ListGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-xl-6 col-lg-12">
                          <div className="advance-profile-row public-profile-sec flex-wrap mb-4">
                            <div className="sec-header mb-2">
                              <h4 className="mr-auto">Public profile</h4>
                            </div>
                            <ListGroup className="info-list w-100 mb-3 mt-2">
                              <ListGroup.Item>
                                <p className="mb-0">
                                  Url :{" "}
                                  <a
                                    href={
                                      RecordLincURL +
                                      "p/" +
                                      userInfo.PublicProfile
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {RecordLincURL +
                                      "p/" +
                                      userInfo.PublicProfile}
                                  </a>
                                </p>
                              </ListGroup.Item>
                            </ListGroup>
                            <div className="inner-profile-sec w-100">
                              <div className="profile-btn-group">
                                <button
                                  type="button"
                                  className="btn app-btn large-btn mb-2 mr-3"
                                  onClick={() => SetProfileSectionModal(true)}
                                >
                                  Choose Your Section
                                </button>
                                <button
                                  type="button"
                                  className="btn app-btn large-btn mb-2"
                                  onClick={() => SetWidgetMenuModal(true)}
                                >
                                  Choose Your Widget
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        */}

                        <div className="col-xl-6 col-lg-12">
                          <div className="edu-section mb-4">
                            <div className="sec-header mb-2">
                              <h4 className="mr-auto  ">Social Media</h4>
                              <button
                                className="app-btn app-btn-clear sec-action-btn"
                                onClick={() => SetEditSocialMediaModel(true)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 17"
                                >
                                  <path
                                    fill="#707070"
                                    d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                  />
                                </svg>{" "}
                                Edit
                              </button>
                            </div>
                            <p className="social-icons-list">
                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .FacebookUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .FacebookUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .FacebookUrl
                                    }
                                    target="_blank"
                                    title="Facebook"
                                  >
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .LinkedinUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .LinkedinUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .LinkedinUrl
                                    }
                                    target="_blank"
                                    title="Linkedin"
                                  >
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .TwitterUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .TwitterUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .TwitterUrl
                                    }
                                    target="_blank"
                                    title="Twitter"
                                  >
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .GoogleplusUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .GoogleplusUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .GoogleplusUrl
                                    }
                                    target="_blank"
                                    title="Google plus"
                                  >
                                    <i
                                      className="fa fa-google-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .YoutubeUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .YoutubeUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .YoutubeUrl
                                    }
                                    target="_blank"
                                    title="Youtube"
                                  >
                                    <i
                                      className="fa fa-youtube"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .PinterestUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .PinterestUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .PinterestUrl
                                    }
                                    target="_blank"
                                    title="Pinterest"
                                  >
                                    <i
                                      className="fa fa-pinterest"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .BlogUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .BlogUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .BlogUrl
                                    }
                                    target="_blank"
                                    title="Blog"
                                  >
                                    <i
                                      className="fa fa-rss-square"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}
                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .InstagramUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .InstagramUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .InstagramUrl
                                    }
                                    target="_blank"
                                    title="Instagram"
                                  >
                                    <i
                                      className="fa fa-instagram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}

                              {userInfo.lstGetSocialMediaDetailByUserId &&
                                userInfo.lstGetSocialMediaDetailByUserId
                                  .length > 0 &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .YelpUrl &&
                                userInfo.lstGetSocialMediaDetailByUserId[0]
                                  .YelpUrl.length > 0 && (
                                  <a
                                    href={
                                      userInfo
                                        .lstGetSocialMediaDetailByUserId[0]
                                        .YelpUrl
                                    }
                                    target="_blank"
                                    title="Yelp"
                                  >
                                    <i
                                      className="fa fa-yelp"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <div className="advance-profile-row">
                        <div className="sec-header ">
                          <h4 className="mr-auto">Education and Training</h4>
                          <button
                            className="app-btn app-btn-clear edit-btn "
                            onClick={() => SetAddEducationInfomationModel(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 17"
                            >
                              <path
                                fill="#707070"
                                d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="inner-profile-sec profile-table-sec">
                          <Table className="profile-table">
                            <thead>
                              <tr>
                                <th>Institution Attended</th>
                                <th>Field of Study/Degree </th>
                                <th>Last Year Attended/Graduated</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div> */}

                      <div className="advance-profile-row upload-img-sec">
                        <div className="sec-header ">
                          <h4 className="mr-auto">Gallery</h4>
                        </div>
                        <div className="image-upload-sec">
                          <div className="image-upload-inner">
                            <div
                              className="single-img upload-img"
                              style={{ backgroundImage: `url(${uploadIcon})` }}
                              onClick={() => SetAddImageGalleryModal(true)}
                            >
                              {/* <Input type="file" name="file" id="exampleFile" /> */}
                            </div>

                            {userInfo.lstGallary &&
                              userInfo.lstGallary.length > 0 &&
                              userInfo.lstGallary.map((gall, index) => (
                                <div
                                  className="single-img"
                                  style={{
                                    backgroundImage: `url(${userInfo.GallaryPath}/${gall.FileName})`,
                                  }}
                                >
                                  <button
                                    onClick={() =>
                                      deleteGallaryImage(gall.GallaryId)
                                    }
                                    className="close-btn btn btn-secondary"
                                  ></button>
                                </div>
                              ))}

                            {/* <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_1})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_2})` }}
                            ></div>

                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_3})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_4})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_1})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_2})` }}
                            ></div>
                            <div
                              className="single-img"
                              style={{ backgroundImage: `url(${Banner_3})` }}
                            ></div> */}
                          </div>
                        </div>
                        {/* <div className="inner-profile-sec">
                          <div className="profile-btn-group mt-2">
                              <button type="button" className="btn app-btn large-btn mr-3" onClick={() => SetAddImageGalleryModal(true)}>Add Gallery Item</button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="user-location">
                  <div className="inner-container px-0 pb-0">
                    <div className="sec-header custom-header">
                      <h4 className="mr-auto">Locations</h4>
                      <button
                        className="app-btn app-btn-clear add-btn "
                        onClick={() => SetAddLocationModel(true)}
                      >
                        + Add New Location
                      </button>
                    </div>
                    <div className="location-container border-bottom">
                      <div className="location-info active">
                        <button
                          className="app-btn app-btn-clear edit-btn "
                          onClick={() => SetEditLocationModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>
                        </button>
                        <h4>
                          Watterson Parkway <small>(Primary)</small>
                        </h4>
                        <p>
                          116 Watterson Parkway Trusville,
                          <br /> AL 35173.
                        </p>
                        <a
                          className="contact-link"
                          href="mailto:test.generaldentist@gmail.com"
                        >
                          test.generaldentist@gmail.com
                        </a>
                        <a className="contact-link" href="tel:(480)9993342">
                          {" "}
                          (480) 999-3342
                        </a>
                        <p>NPI Number :-</p>
                      </div>
                      <div className="location-info">
                        <button
                          className="app-btn app-btn-clear edit-btn "
                          onClick={() => SetEditLocationModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>
                        </button>
                        <h4>Birmingham </h4>
                        <p>
                          116 Watterson Parkway Trusville,
                          <br /> AL 35173.
                        </p>
                        <a
                          className="contact-link"
                          href="mailto:test.generaldentist@gmail.com"
                        >
                          test.generaldentist@gmail.com
                        </a>
                        <a className="contact-link" href="tel:(480)9993342">
                          {" "}
                          (480) 999-3342
                        </a>
                        <p>NPI Number :-</p>
                      </div>
                    </div>
                    <div className="location-container second-row">
                      <div className="location-info">
                        <button
                          className="app-btn app-btn-clear edit-btn "
                          onClick={() => SetEditLocationModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>
                        </button>
                        <h4>Atlanka Street</h4>
                        <p>
                          116 Watterson Parkway Trusville,
                          <br /> AL 35173.
                        </p>
                        <a
                          className="contact-link"
                          href="mailto:test.generaldentist@gmail.com"
                        >
                          test.generaldentist@gmail.com
                        </a>
                        <a className="contact-link" href="tel:(480)9993342">
                          {" "}
                          (480) 999-3342
                        </a>
                        <p>NPI Number :-</p>
                      </div>
                      <div className="location-info">
                        <button
                          className="app-btn app-btn-clear edit-btn "
                          onClick={() => SetEditLocationModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>
                        </button>
                        <h4>Chandler Arcade</h4>
                        <p>
                          116 Watterson Parkway Trusville,
                          <br /> AL 35173.
                        </p>
                        <a
                          className="contact-link"
                          href="mailto:test.generaldentist@gmail.com"
                        >
                          test.generaldentist@gmail.com
                        </a>
                        <a className="contact-link" href="tel:(480)9993342">
                          {" "}
                          (480) 999-3342
                        </a>
                        <p>NPI Number :-</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>

              {/* <div className="white-container ">

              </div> */}
            </div>
            {loaderForProfile && <AppPageLoader />}
          </div>
        </div>
      </main>
      <AddLocationProfileModel
        show={AddLocationModelShow}
        onHide={() => SetAddLocationModel(false)}
      />
      <EditLocationProfileModel
        show={EditLocationModelShow}
        onHide={() => SetEditLocationModel(false)}
      />
      <EditUserProfileModel
        show={EditProfileModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetEditProfileModel(false)}
      />
      <AddBannerModal
        show={AddBannerModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddBannerModal(false)}
      />

      <AddImageGalleryModal
        show={AddImageGalleryModalShow}
        reload={() => reloadPage()}
        onHide={() => SetAddImageGalleryModal(false)}
      />
      <ProfileSectionModal
        show={ProfileSectionModalShow}
        userData={userInfo.ObjProfileSection}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetProfileSectionModal(false)}
      />
      <WidgetMenuModal
        show={WidgetMenuModalShow}
        profileName={userInfo.PublicProfile}
        reload={() => reloadPage()}
        onHide={() => SetWidgetMenuModal(false)}
      />
      <AddWebsiteModal
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        show={AddWebsiteModalShow}
        SecondaryWebsiteId={SecondaryWebsiteId}
        onHide={() => SetAddWebsiteModal(false)}
      />
      <EditWebsiteModal
        show={EditWebsiteModalShow}
        onHide={() => SetEditWebsiteModal(false)}
      />
      <SocialMediaModal
        show={SocialMediaModalShow}
        onHide={() => SetSocialMediaModal(false)}
      />
      <AddDescriptionModal
        show={AddDescriptionModalShow}
        onHide={() => SetAddDescriptionModal(false)}
      />
      <AddEducationInfomationModel
        selectedEdu={selectedEdu}
        reload={() => reloadPage()}
        show={AddEducationInfomationModelShow}
        onHide={() => SetAddEducationInfomationModel(false)}
      />
      <AddMembershipModal
        show={AddMembershipModalShow}
        onHide={() => SetAddMembershipModal(false)}
      />
      <AddInsuranceDetailModal
        show={AddInsuranceDetailModalShow}
        onHide={() => SetAddInsuranceDetailModal(false)}
      />
      <AddProcedureDetailModel
        show={AddProcedureDetailModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddProcedureDetailModel(false)}
      />
      <AddEduTrainingInfoPopup
        show={AddEduTrainingInfoPopupShow}
        onHide={() => SetAddEduTrainingInfoPopup(false)}
      />
      <EditEduTrainingInfoPopup
        show={EditEduTrainingInfoPopupShow}
        onHide={() => SetEditEduTrainingInfoPopup(false)}
      />
      <AddProfessionalMembershipModal
        show={AddProfessionalMembershipModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        profeMembership={profeMembership}
        onHide={() => SetAddProfessionalMembershipModal(false)}
      />
      <EditProfessionalMembershipModal
        show={EditProfessionalMembershipModalShow}
        onHide={() => SetEditProfessionalMembershipModal(false)}
      />
      <AddInsuranceDetailsModal
        show={AddInsuranceDetailsModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddInsuranceDetailsModal(false)}
      />
      <EditSocialMediaModel
        show={EditSocialMediaModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetEditSocialMediaModel(false)}
      />
      <AddProfileImageModal
        show={AddProfileImageModalShow}
        reload={() => reloadPage()}
        onHide={() => SetAddProfileImageModal(false)}
      />
    </div>
  );
}
