import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import TopNavBar from "../common-components/TopNavbar";
// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
// import AddLocationModel1 from "../models/AddLocationModel1";
// import AddLocationModel1Edit from "../models/AddLocationModel1Edit";
import classnames from "classnames";
// import { Nav, NavItem, NavLink } from 'reactstrap';
//images import
import userThumb from "../assets/images/user-thumb-1.png";
//user-thumb-1.png
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  // NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Form,
  Col,
} from "reactstrap";
import Select from "react-select";

export default function Setting() {
  const [curPasswordShown, setCurPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  function eyeToggleNew(event) {
    toggleCssClass(event.target, "fa-eye-slash");
    setNewPasswordShown(newPasswordShown ? false : true);
  }
  function eyeToggleCur(event) {
    toggleCssClass(event.target, "fa-eye-slash");
    setCurPasswordShown(curPasswordShown ? false : true);
  }
  function eyeToggleCon(event) {
    toggleCssClass(event.target, "fa-eye-slash");
    setConPasswordShown(conPasswordShown ? false : true);
  }
  function toggleCssClass(element, eleClass) {
    element.classList.toggle(eleClass);
  }
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const locationoptions = [
    { value: "Dummy Location Name", label: "Dummy Location Name" },
  ];

  const [selectedOption, setselectedOption] = useState(null);
  const [selectedlocationOption, setselectedlocationOption] = useState(null);

  const [activeTab, setActiveTab] = useState("1");
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [AddLocationModelEditShow, SetAddLocationEditModel] = React.useState(
    false
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChangeLocation = (selectedlocationOption) => {
    setselectedlocationOption(selectedlocationOption);
  };

  // <AddLocationModel1Edit show={AddLocationModelEditShow} onHide={() => SetAddLocationEditModel(false)} />

  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Settings
              </li>
            </ol>
          </nav>
          <div className="app-content">
            <div className="setting-page">
              <div className="white-container">
                <div className="w-100">
                {/* <Nav className="setting-nav-menu">
                  <NavItem>
                    <NavLink exact to="/profile" href="/profile">Profile</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink exact to="/billing" href="/billing">Billing</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="active" exact to="/setting" href="/setting">Account Setting</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink exact to="/comingSoon" href="/comingSoon">Team Members</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink exact to="/ChangePassPage" href="/ChangePassPage">Changes Password</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink exact to="/" href="/">Integration</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink exact to="/comingSoon" href="/comingSoon">Support</NavLink>
                  </NavItem>
                </Nav> */}
                </div>
                <div className="w-100">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        exact 
                        to="/profile"
                        href="/profile"
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        exact 
                        to="/billing"
                        href="/billing"
                      >
                        Billing
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Account Settings
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Set Goals
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Team Members
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        Change Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        exact 
                        to="/"
                        href="/"
                      >
                        Integration
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        exact 
                        to="/comingSoon"
                        href="/comingSoon"
                      >
                        Support
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <Form className="account-tab">
                            <div className="step-block">
                              <Row>
                              <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      User Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="1234 5678 9123"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Primary Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Johndoe@gmail.com"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Secondary Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="Johndoe123@gmail.com"
                                    />
                                  </div>
                                </Col>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Stay logged in for
                                    </label>
                                    <Select
                                      className="select-menu"
                                      value={selectedOption}
                                      onChange={handleChange}
                                      options={options}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">Location</label>
                                    <Select
                                    className="select-menu"
                                      value={selectedlocationOption}
                                      onChange={handleChangeLocation}
                                      options={locationoptions}
                                    />
                                  </div>
                                </Col>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="">Time Zone </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue="(GMT-05:00) Eastern Time (US & Canada)"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12}>
                                <div class="btn-box d-flex ">
                                  <button
                                    type="button"
                                    class="btn app-btn lightgray-btn large-btn mr-2"
                                  >
                                    cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn app-btn large-btn "
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">Set Goal Tab</TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <div className="billing-page">
                            <div className="grid-section">
                              <div className="white-container p-0">
                                <div className="action-row d-flex pt-0">
                                  <div className="left-box d-flex">
                                    <div className="form-group app-select">
                                      <select className="form-control">
                                        <option>All Location</option>
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                      </select>
                                    </div>
                                    <div className="form-group app-select">
                                      <select className="form-control">
                                        <option>Only Staff</option>
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="right-box ml-auto d-flex align-items-start">
                                    <div className="form-group input-group app-search">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                      />
                                      <span className="search-icon">
                                        <i className="fa fa-search"></i>
                                      </span>
                                      <button className="btn search-btn">
                                        <span>Search</span>
                                        <i className="fa fa-search"></i>
                                      </button>
                                    </div>
                                    {/* <NavLink
                                      exact
                                      to="/billing/paymentmethod"
                                      className="btn app-btn small-btn text-uppercase"
                                    >
                                      Payment Method
                                    </NavLink> */}
                                    {/* <button className="btn app-btn small-btn text-uppercase">Payment Method</button> */}
                                  </div>
                                </div>
                                <div className="app-table">
                                  <div className="table-responsive">
                                    <table className="table table-hover table-borderless invoice-grid">
                                      <thead>
                                        <tr>
                                          <th className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="chkall"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="chkall"
                                              ></label>
                                            </div>
                                          </th>
                                          <th>Last Name </th>

                                          <th>First Name </th>

                                          <th>Email</th>
                                          <th>Speciality</th>
                                          <th>Location</th>
                                          <th>Type</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="chk1"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="chk1"
                                              ></label>
                                            </div>
                                          </td>
                                          <td>Doe</td>

                                          <td>John</td>
                                          <td>Johndoe@gmail.com</td>
                                          <td>General Dentist</td>
                                          <td>Chandler Office</td>
                                          <td>Provider</td>
                                          <td>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="edit-2"
                                                size="20"
                                              />
                                            </button>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="trash-2"
                                                size="20"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="chk1"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="chk1"
                                              ></label>
                                            </div>
                                          </td>
                                          <td>Jennings</td>

                                          <td>Kristie</td>
                                          <td>KristieIJennings@gmail.com</td>
                                          <td>General Dentist</td>
                                          <td>Chandler Office</td>
                                          <td>Staff</td>
                                          <td>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="edit-2"
                                                size="20"
                                              />
                                            </button>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="trash-2"
                                                size="20"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="chk1"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="chk1"
                                              ></label>
                                            </div>
                                          </td>
                                          <td>Doe</td>

                                          <td>John</td>
                                          <td>Johndoe@gmail.com</td>
                                          <td>General Dentist</td>
                                          <td>Chandler Office</td>
                                          <td>Provider</td>
                                          <td>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="edit-2"
                                                size="20"
                                              />
                                            </button>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="trash-2"
                                                size="20"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="chk-col">
                                            <div className="custom-control custom-checkbox app-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="chk1"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="chk1"
                                              ></label>
                                            </div>
                                          </td>
                                          <td>Jennings</td>

                                          <td>Kristie</td>
                                          <td>KristieIJennings@gmail.com</td>
                                          <td>General Dentist</td>
                                          <td>Chandler Office</td>
                                          <td>Staff</td>
                                          <td>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="edit-2"
                                                size="20"
                                              />
                                            </button>
                                            <button className="action-btn app-btn-clear">
                                              <FeatherIcon
                                                icon="trash-2"
                                                size="20"
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <Form className="password-tab">
                            <div className="step-block">
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      Current Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={curPasswordShown ? "text" : "password"}
                                        className="form-control"
                                        defaultValue="Johndoe123@gmail.com"
                                      />
                                      <i className="input-icon pass-icon-eye eyeToggle fa fa-eye fa-eye-slash" onClick={eyeToggleCur}></i>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="required">
                                      New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={newPasswordShown ? "text" : "password"}
                                        className="form-control"
                                        defaultValue="12345678"
                                      />
                                      <i className="input-icon pass-icon-eye eyeToggle fa fa-eye fa-eye-slash" onClick={eyeToggleNew}></i>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} xl={4}>
                                  <div className="form-group app-ctrl">
                                    <label className="">
                                      Confirm New Password
                                    </label>
                                    <div className="with-icon">
                                      <input
                                        type={conPasswordShown ? "text" : "password"}
                                        className="form-control"
                                        defaultValue="12345678"
                                      />
                                      <i className="input-icon pass-icon-eye eyeToggle fa fa-eye fa-eye-slash" onClick={eyeToggleCon}></i>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12}>
                                <div class="btn-box d-flex ">
                                  <button
                                    type="button"
                                    class="btn app-btn lightgray-btn large-btn mr-2"
                                  >
                                    cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn app-btn large-btn "
                                  >
                                    Update
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <AddLocationModel1
        show={AddLocationModelShow}
        onHide={() => SetAddLocationModel(false)}
      />
      <AddLocationModel1Edit
        show={AddLocationModelEditShow}
        onHide={() => SetAddLocationEditModel(false)}
      /> */}
    </div>
  );
}
