import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Api from "../API/login";
import { history } from "../history";

import AppPageLoader from "../common-components/AppPageLoader";

export default function AuthKey() {
  // let history = useHistory();
  const redirectToSignUp = () => {
    history.push("/signup");
  };
  const redirectToForgotPassword = () => {
    history.push("/ForgotPasswordPage");
  };

  const { token } = useParams();
  useEffect(() => {
    let decTokenKey = window.atob(token);
    let decToken = null;
    let key = null;
    if (token && token.length > 0) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      localStorage.removeItem("sectionList");
      let data = decTokenKey.split("$$");
      if (data[0]) {
        decToken = data[0];
      }
      if (data[1]) {
        key = window.btoa(data[1]);
      }
    }

    if (key && decToken) {
      Api.authentication(
        { AuthenticationKey: key },
        {
          headers: {
            "Content-Type": "application/json",
            access_token: decToken,
          },
        }
      )
        .then((res) => {
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          window.location.href = "/setting/profile";
        })
        .catch((e) => {
          // window.location.href = "/signin";
        });
    } else {
      //  window.location.href = "/signin";
    }
  }, [token]);

  return (
    <div
      className="position-relative"
      style={{ width: "100vw", height: "100vh" }}
    >
      <AppPageLoader></AppPageLoader>
    </div>
  );
}
