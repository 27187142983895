import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../API/team-members";
import AppPageLoader from "../common-components/AppPageLoader";

export default function RemoveMemberModal(props) {
    const [LocationAisActive, SetLocationAState] = React.useState(false);

    const [loader, setLoader] = React.useState(false);

    const deleteRecord = () => {
      let payLoad = {TeamMemberId: [props.memeberid]};
      setLoader(true);
      Api.removeTeamMemer(payLoad)
         .then(() => {
          toast.success("Remove successfully!");
          props.onHide(true);
          setLoader(false);
         })
         .catch(() => {
           toast.error("Something went wrong");
           setLoader(false);
         })
    }
    
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Remove Team Member
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
                    <h3 className="remove-list mt-3 mb-4">Are you sure to remove this team member?</h3>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={() => props.onHide(false)}>cancel</button>
              <button type="button" className="btn app-btn large-btn " onClick={deleteRecord}>Yes</button>
            </div>

          </form>
          {loader && <AppPageLoader />}
        </Modal.Body>

      </Modal>
    </div>
  );
}


