import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// component imports
import TopNavBar from "../common-components/TopNavbar";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import EmailInstallInstructionModel from "../models/EmailInstallInstructionModel";
import ServerStepIllustration from "./ServerStepIllustration";
import SystemSetupStepCheckPage from "./SystemSetupStepCheckPage";
//images import
import BrandImg1 from "../assets/images/7.png";
import Api from "../API/integration";

import AppPageLoader from "../common-components/AppPageLoader";

export default function ManageConnectionPage() {
  const [setupSwitchCheck, setSetupSwitchCheck] = useState(false);
  const [EmailInstallInstructionModelShow, SetEmailInstallInstructionModel] =
    React.useState(false);

  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState({});

  useEffect(() => {
    setLoader(true);
    Api.getIntegration()
      .then((resp) => {
        setLoader(false);
        if (resp.data && resp.data.Result) {
          for (let index = 0; index < resp.data.Result.length; index++) {
            const element = resp.data.Result[index];
            if (element.Id == id) {
              setSelectedIntegration(element);
            }
          }
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  }, [id]);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <TopNavBar />

          <div className="app-content">
            <div className="white-container system-setupInfo-page  position-relative">
              <div className="inner-container">
                <div className="page-info">
                  <div className="position-relative mb-4">
                    <nav aria-label="breadcrumb" className="app-breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item ">
                          <Link exact to="/setting/integration">
                            Integrations{" "}
                          </Link>
                        </li>
                        <li className="breadcrumb-item ">
                          <Link
                            exact
                            to={
                              "/integration/location/" +
                              selectedIntegration.Id +
                              "/" +
                              selectedIntegration.Name
                            }
                          >
                            {selectedIntegration.Name}{" "}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          <a>Manage</a>
                        </li>
                      </ol>
                    </nav>
                    <p className="header-note">
                      Follow the steps below to install our proprietary data
                      Sync tool on your system.
                    </p>
                    {/* {id == 1 || id == 12 ? (
                      ""
                    ) : (
                      <div className="setup-custSwitch">
                        <label>
                          <span className="switch-labels">
                            <a href={selectedIntegration.ServerSetupURL}>
                              <span> Server setup </span>
                            </a>
                          </span>
                        </label>
                      </div>
                    )} */}
                  </div>
                  <div className="header-box d-flex align-items-center justify-content-between flex-wrap">
                    <div className="product-info">
                      <div
                        className="logo-box"
                        style={{
                          backgroundImage: `url(${selectedIntegration.ImageURL})`,
                        }}
                      ></div>
                      {id == 1 || id == 12 ? (
                        ""
                      ) : (
                        <p className="pt-1">
                          {selectedIntegration.Description}
                        </p>
                      )}
                    </div>

                    {id != 12 && (
                      <div className="action-btns">
                        <a
                          target="_blank"
                          href={selectedIntegration.DownloadPDF}
                        >
                          <button className="btn app-btn with-icon my-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 25 25"
                            >
                              <path d="M16.469 4.312V.813l4.96 5.205h-3.246a1.704 1.704 0 01-1.714-1.706zm5.691 2.763v15.52c0 1.32-1.09 2.41-2.41 2.41H5.25a2.43 2.43 0 01-2.41-2.41V2.405c0-1.32 1.1-2.41 2.41-2.41h10.16v4.32c0 1.52 1.24 2.76 2.77 2.76h3.98zM8.23 13.686l3.87 4.159c.11.101.24.16.39.16.16 0 .29-.06.39-.16l3.879-4.159a.534.534 0 00-.029-.75.522.522 0 00-.74.03l-2.96 3.17v-7.56c0-.29-.24-.53-.53-.53s-.53.24-.53.53v7.56l-2.95-3.18a.544.544 0 00-.75-.03.56.56 0 00-.04.76zm10.241 7.279c0-.29-.23-.53-.521-.53H7.061c-.29 0-.53.24-.53.53s.24.53.53.53h10.88c.289 0 .53-.24.53-.53z" />
                            </svg>
                            <span> View setup instructions</span>
                          </button>
                        </a>
                        <button
                          className="btn blue-btn app-btn  with-icon my-1"
                          onClick={() => SetEmailInstallInstructionModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 19"
                          >
                            <path d="M.501 1.46a.5.5 0 01-.07-.69C.814.281 1.387 0 2 0h20c.613 0 1.186.281 1.569.771a.5.5 0 01-.07.69c-3.294 2.79-9.073 7.697-10.772 9.227-.228.207-.473.312-.727.312-.253 0-.498-.104-.725-.311C9.575 9.158 3.795 4.25.501 1.46zM23.71 2.973a.499.499 0 00-.533.072c-2.981 2.527-8.218 6.978-9.78 8.386-.826.745-1.968.745-2.792.002C9.138 10.111 4.476 6.141.823 3.045A.498.498 0 000 3.427v12.574c0 1.104.897 2 2 2h20a2 2 0 002-2V3.427a.5.5 0 00-.29-.454z" />
                          </svg>
                          <span> Email install instructions</span>
                        </button>

                        <a
                          target="_blank"
                          href="https://calendly.com/drdds/paidintegration"
                        >
                          <button className="btn app-btn with-icon my-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 25 25"
                            >
                              <path d="M16.469 4.312V.813l4.96 5.205h-3.246a1.704 1.704 0 01-1.714-1.706zm5.691 2.763v15.52c0 1.32-1.09 2.41-2.41 2.41H5.25a2.43 2.43 0 01-2.41-2.41V2.405c0-1.32 1.1-2.41 2.41-2.41h10.16v4.32c0 1.52 1.24 2.76 2.77 2.76h3.98zM8.23 13.686l3.87 4.159c.11.101.24.16.39.16.16 0 .29-.06.39-.16l3.879-4.159a.534.534 0 00-.029-.75.522.522 0 00-.74.03l-2.96 3.17v-7.56c0-.29-.24-.53-.53-.53s-.53.24-.53.53v7.56l-2.95-3.18a.544.544 0 00-.75-.03.56.56 0 00-.04.76zm10.241 7.279c0-.29-.23-.53-.521-.53H7.061c-.29 0-.53.24-.53.53s.24.53.53.53h10.88c.289 0 .53-.24.53-.53z" />
                            </svg>
                            <span> schedule full service install</span>
                          </button>
                        </a>
                      </div>
                    )}
                  </div>
                  {/* <div className={`steps-info-box ${setupSwitchCheck ? "d-none":""}`}>
                                        <SystemSetupStepCheckPage/>
                                    </div> */}
                  {/* <div className={`steps-info-box serverSetup-box py-4 py-lg-5 ${setupSwitchCheck ? "":"d-none"}`}> */}
                </div>
              </div>
              {loader && <AppPageLoader></AppPageLoader>}
            </div>
          </div>
        </div>
      </main>
      <EmailInstallInstructionModel
        integrationId={id}
        modelStatus={EmailInstallInstructionModelShow}
        show={EmailInstallInstructionModelShow}
        onHide={() => SetEmailInstallInstructionModel(false)}
      />
    </div>
  );
}
