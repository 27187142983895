import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
//component import
import * as inputFun from "../common-components/FormInputFunctions";
import { Spinner } from "reactstrap";
import {
  validZipCodeRegex,
  validPhoneRegex,
  validEmailRegex,
} from "../config/appRegex";
import { validateForm } from "../config/commonFun";
import { ToastContainer, toast } from "react-toastify";
import Api from "../API/add-new-location-modal";

export default function AddLocationModel(props) {
  const [newBtnLoader, SetNewBtnLoader] = useState(false);

  const [newAddress, setNewAddress] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [newAddressErrors, setNewAddressErrors] = useState({
    State: "",
    Country: "",
    Name: "",
    Address1: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    NPINumber: "",
  });

  const [state, setState] = useState({
    Id: 1,
    Name: "",
    Code: "",
  });

  const handelNewAddressChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    // value = value.trim();
    let errorValue = "";
    if (name != "State" && name != "Primary") {
      setNewAddress((address) => ({
        ...address,
        [name]: value,
      }));
    }
    switch (name) {
      case "Name":
        errorValue =
          value.trim().length == 0 ? "Location Name is required." : "";
        break;
      case "Address1":
        errorValue =
          value.trim().length == 0 ? "Street Address is required." : "";
        break;
      case "Email":
        if (value.trim().length == 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        errorValue = validPhoneRegex.test(value)
          ? ""
          : "Enter valid Phone Number.";
        break;
      case "ZipCode":
        if (value.trim().length == 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value)
            ? ""
            : "Enter valid Zip Code.";
        }
        break;
      case "City":
        errorValue = value.trim().length == 0 ? "City is required." : "";
        break;
      case "State":
        let objState = newAddress.State;
        if (objState != null) {
          objState.Code = value;
          newAddress.State = objState;
        } else {
          let obj = {
            Id: 0,
            Name: "",
            Code: "",
          };
          obj.Code = value;
          newAddress.State = obj;
        }
        errorValue = value.trim().length == 0 ? "State is required." : "";
        break;
      // case "NPINumber":
      //     errorValue = value.trim().length == 0 ? "NPI Number is required." : "";
      //     break;

      default:
        break;
    }
    setNewAddressErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelNewAddressSubmit = (event) => {
    let flagError = false;
    event.persist();
    event.preventDefault();

    if (newAddress.Name.length == 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Name: "Location Name is required.",
      }));
      flagError = true;
    }
    if (newAddress.Address1.length == 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Address1: "Street Address is required.",
      }));
      flagError = true;
    }
    if (newAddress.City.length == 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (
      newAddress.State == null ||
      newAddress.State.Code == null ||
      newAddress.State.Code == undefined ||
      newAddress.State.Code == 0
    ) {
      setNewAddressErrors((errors) => ({
        ...errors,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (newAddress.ZipCode.length == 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        ZipCode: "ZipCode is required.",
      }));
      flagError = true;
    }
    if (newAddress.Email.length == 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (validateForm(newAddressErrors) && !flagError) {
      SetNewBtnLoader(true);
      let payLoad = { ...newAddress };
      if (newAddressPrimaryCKB) {
        payLoad.ContactType = 1;
      }
      Api.postAddress(payLoad)
        .then((res) => {
          toast.success("Location Add successfully!");
          SetNewBtnLoader(false);
          props.reload();
          props.onHide();
        })
        .catch((error) => {
          SetNewBtnLoader(false);
          if (error.response.status == 400 && error.response.data.Result) {
            toast.error(error.response.data.Message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  const [newAddressPrimaryCKB, setNewAddressPrimaryCKB] = useState(false);

  const getState = () => {
    Api.getState("US")
      .then((res) => {
        setState(res.data.Result);
      })
      .catch((error) => {});
  };

  const toggleNewPrimaryCKB = (event) => {
    setNewAddressPrimaryCKB(!newAddressPrimaryCKB);
  };

  useEffect(() => {
    if (props.modelState) {
      getState();
    }
    setNewAddressErrors({
      State: "",
      Country: "",
      Name: "",
      Address1: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      NPINumber: "",
    });
    setNewAddressPrimaryCKB(false);
    setNewAddress({
      State: {
        Id: 0,
        Name: "",
        Code: "",
      },
      Country: {
        Id: 0,
        Name: "",
        Code: "",
        ISOCode: "",
      },
      Id: 0,
      Name: "",
      Address1: "",
      Address2: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      ContactType: 0,
      NPINumber: "",
    });
  }, [props.modelState]);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal addLocation-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Add New New Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelNewAddressSubmit}>
            <div className="row insurance-detail-sec">
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Location Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.Name}
                    name="Name"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.Name.length > 0 && (
                    <div className="err-message">{newAddressErrors.Name}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Street Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.Address1}
                    name="Address1"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.Address1.length > 0 && (
                    <div className="err-message">
                      {newAddressErrors.Address1}
                    </div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">City</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.City}
                    name="City"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.City.length > 0 && (
                    <div className="err-message">{newAddressErrors.City}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">State</label>
                  <select
                    className="form-control"
                    name="State"
                    onChange={handelNewAddressChange}
                    value={
                      newAddress.State != null ? newAddress.State.Code : ""
                    }
                  >
                    <option value="">Select State</option>
                    {state.length > 0 &&
                      state.map((s) => (
                        <option value={s.Code}>{s.Name}</option>
                      ))}
                  </select>
                  {newAddressErrors.State.length > 0 && (
                    <div className="err-message">{newAddressErrors.State}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Zip code</label>
                  {/* <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/> */}
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.ZipCode}
                    name="ZipCode"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.ZipCode.length > 0 && (
                    <div className="err-message">
                      {newAddressErrors.ZipCode}
                    </div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.Email}
                    name="Email"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.Email.length > 0 && (
                    <div className="err-message">{newAddressErrors.Email}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.Phone}
                    name="Phone"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.Phone.length > 0 && (
                    <div className="err-message">{newAddressErrors.Phone}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label>Fax Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newAddress.Fax}
                    name="Fax"
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.Fax.length > 0 && (
                    <div className="err-message">{newAddressErrors.Fax}</div>
                  )}
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label>NPI Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="NPINumber"
                    value={newAddress.NPINumber}
                    onChange={handelNewAddressChange}
                  />
                  {newAddressErrors.NPINumber.length > 0 && (
                    <div className="err-message">
                      {newAddressErrors.NPINumber}
                    </div>
                  )}
                </div>
              </div>

               {newAddress.NPINumber.length ==0 &&                                                    
                <div className=" col-12 ">
                    <a href="https://npiregistry.cms.hhs.gov/"> <i className="fa fa-search"> </i> Lookup NPI Number</a>
                </div>
                }
              <div className=" col-12 chk-col mt-2">
                <div
                  className="custom-control custom-checkbox app-checkbox"
                  onClick={toggleNewPrimaryCKB}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={newAddressPrimaryCKB}
                  />
                  <label className="custom-control-label" htmlFor="chk2">
                    Set as Primary Location
                  </label>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                {newBtnLoader && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2 location-spinner"
                  />
                )}
                Add location
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
