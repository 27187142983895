import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";
// main api class
class Api {
  static postAddress = (model) => {
    let data = {
      State: model.State.Code,
      Country: "US",
      Id: model.Id,
      Name: model.Name,
      Address1: model.Address1,
      Address2: model.Address2,
      City: model.City,
      ZipCode: model.ZipCode,
      Email: model.Email,
      Phone: model.Phone,
      Fax: model.Fax,
      ContactType: model.ContactType,
      NPINumber: model.NPINumber,
    };
    return axios.post(API_URL + "/Address/Post/", data, HEADER);
  };

  static getState = (countryId) => {
    return axios.get(API_URL + "/State/Get/" + countryId, HEADER);
  };
}
export default Api;
