import axios from "axios";
import { API_URL, HEADER, HEADERMultipart } from "../config/appConstatnts";

class Api {
  static getIntegration = () => {
    return axios.get(API_URL + "/Integration/Get", HEADER);
  };

  static getIntegrationList = () => {
    return axios.get(API_URL + "/Integrationlist", HEADER);
  };

  static getIntegrationLocations = (data) => {
    return axios.post(API_URL + "/Integration/Locations", data, HEADER);
  };

  static locationDisable = (id) => {
    return axios.get(API_URL + "/Settings/DisableConnector?id=" + id, HEADER);
  };

  static locationEnable = (id) => {
    return axios.get(API_URL + "/Settings/EnableConnector?id=" + id, HEADER);
  };

  static getIntegrationKey = () => {
    return axios.get(API_URL + "/GetPMSIntegrationKey", HEADER);
  };

  static saveIntegrationKey = (data) => {
    return axios.post(API_URL + "/Integration/SaveIntegrations", data, HEADER);
  };

  static sendEmail = (data) => {
    return axios.post(API_URL + "/Integration/SendEmail", data, HEADER);
  };

  static getAgencyAccountDetails = () => {
    return axios.get(
      API_URL + "/Integration/GetGoHighLevelAgencyDetails",
      HEADER
    );
  };

  static addUpdateAgencyKey = (data) => {
    return axios.post(
      API_URL + "/Integration/AddUpdateGoHighLevelAgencyKey",
      data,
      HEADER
    );
  };

  static enableDisableTypes = (isEnable, type) => {
    return axios.post(
      API_URL +
        "/Settings/GoHighLevelEnableDisable?isEnable=" +
        isEnable +
        "&type=" +
        type,
      {},
      HEADER
    );
  };

  static UpdateGoHighLevelKey = (data) => {
    return axios.post(API_URL + "/Settings/UpdateGoHighLevelKey", data, HEADER);
  };

  static GoHighLevelLocationEnableDisable = (isEnable, id) => {
    return axios.post(
      API_URL +
        "/Settings/GoHighLevelLocationEnableDisable?IsEnable=" +
        isEnable +
        "&Id=" +
        id,
      {},
      HEADER
    );
  };
}

export default Api;
