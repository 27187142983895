import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Calendly from "../app-pages/Calendly"

// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import AddLocationModel from "../models/AddLocationModel";
import NewLocationEnabledModel from "../models/NewLocationEnabledModel";
import LocationKeyInfoModel from "../models/LocationKeyInfoModel";
//images import
import NoteEditIcon from "../assets/images/noteEdit-icon-black.svg";
import HIPPAlogo from "../assets/images/HIPAA-logo.png";
import TopNavBar from "../common-components/TopNavbar";

import AppPageLoader from "../common-components/AppPageLoader";
import Api from "../API/integration";
import { Spinner } from "reactstrap";

export default function IntegrationDirectoryPage() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [NewLocationEnabledModelShow, SetNewLocationEnabledModel] =
    React.useState(false);
  const [LocationKeyInfoModelShow, SetLocationKeyInfoModel] =
    React.useState(false);

  const [originalAgencyKey, SetOriginalAgencyKey] = React.useState(false);
  const [isAgencyKey, SetIsAgencyKey] = React.useState(false);
  const [AgencyKey, SetAgencyKey] = React.useState(null);
  const [IsEnablePatient, SetIsEnablePatient] = React.useState(false);
  const [IsEnableAppointment, SetIsEnableAppointment] = React.useState(false);

  function coppyMessage() {
    toast.info("Key copied.", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const { id, name } = useParams();

  const [loader, setLoader] = useState(false);
  const [locations, setLocations] = useState([]);
  const [accountId, SetAccountId] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState(null);

  const setLocationKeyInfoModel = (state, item) => {
    
    if (state && item) {
      setSelectedLocations({ ...item });
     
      SetLocationKeyInfoModel(true);
    } else {
      SetLocationKeyInfoModel(false);
      setSelectedLocations(null);
    }
  };

  const [selectedApiKey, setSelectedApiKey] = useState("");

  const setNewLocationEnabledModel = (state, location, isSuccess) => {
    if (state && id != 14) {
      setLoader(true);
      Api.getIntegrationKey()
        .then((resp) => {
          setLoader(false);
          if (resp.data.Result) {
            let selectedLocation = { ...location };
            selectedLocation.key = resp.data.Result;
            selectedLocation.locationId = selectedLocation.LocationId;
            selectedLocation.param =
              id + "_" + resp.data.Result + "_" + selectedLocation.LocationId;
            setSelectedApiKey(selectedLocation);
            SetNewLocationEnabledModel(true);
          } else {
            setSelectedApiKey("");
            toast.error("Something went wrong");
          }
        })
        .catch((e) => {
          setSelectedApiKey("");
          toast.error("Something went wrong");
          setLoader(false);
        });
    } else if (state && id == 14) {
      let selectedLocation = { ...location };
            selectedLocation.key = "";
            selectedLocation.locationId = selectedLocation.LocationId;
            selectedLocation.param = "";
            setSelectedApiKey(selectedLocation);
      SetNewLocationEnabledModel(true);
    }else {
      if (isSuccess) {
        let newlocations = Array.from(locations);
        let selectedLocation = null;
        for (let index = 0; index < newlocations.length; index++) {
          const element = newlocations[index];
          if (element.LocationId == location.LocationId) {
            selectedLocation = element;
          }
        }
        selectedLocation.IsEnable = true;
        selectedLocation.DentrixConnectorId = location.DentrixConnectorId
          ? location.DentrixConnectorId
          : 0;
        setLocations(newlocations);
        setSelectedApiKey("");
        SetNewLocationEnabledModel(false);
        // Api.locationEnable(location.DentrixConnectorId)
        //     .then((resp) => {
        //         setLoader(false);
        //         if (resp.data) {
        //             selectedLocation.IsEnable = true;
        //             setLocations(newlocations);
        //         } else {
        //             selectedLocation.IsEnable = false;
        //             setLocations(newlocations);
        //             toast.error("Something went wrong");
        //         }
        //     })
        //     .catch((e) => {
        //         setLoader(false);
        //         toast.error("Something went wrong");
        //     })
      } else {
        setSelectedApiKey("");
        SetNewLocationEnabledModel(false);
      }
    }
  };

  const enableDisableLocation = (location) => {
    let newlocations = Array.from(locations);
    let selectedLocation = null;
    for (let index = 0; index < newlocations.length; index++) {
      const element = newlocations[index];
      if (element.LocationId == location.LocationId) {
        selectedLocation = element;
      }
    }

    if (
      selectedLocation.DentrixConnectorId == 0 &&
      !selectedLocation.IsEnable
    ) {
      setNewLocationEnabledModel(true, location, true);
    } else {
      setLoader(true);
    
   if(id == 14){
       Api.GoHighLevelLocationEnableDisable(!location.IsEnable, location.DentrixConnectorId)
          .then((resp) => { 
            setLoader(false);
            if (resp.data) {
              selectedLocation.IsEnable = !location.IsEnable;
              setLocations(newlocations);
            } else {
              selectedLocation.IsEnable = !location.IsEnable;
              setLocations(newlocations);
              toast.error("Something went wrong");
            }
          })
          .catch((e) => {
            setLoader(false);
            toast.error("Something went wrong");
          });

   }else{

      if (selectedLocation.IsEnable) {
        Api.locationDisable(selectedLocation.DentrixConnectorId)
          .then((resp) => {
            setLoader(false);
            if (resp.data) {
              selectedLocation.IsEnable = false;
              setLocations(newlocations);
            } else {
              selectedLocation.IsEnable = true;
              setLocations(newlocations);
              toast.error("Something went wrong");
            }
          })
          .catch((e) => {
            setLoader(false);
            toast.error("Something went wrong");
          });
      } else {
        Api.locationEnable(location.DentrixConnectorId)
          .then((resp) => {
            setLoader(false);
            if (resp.data) {
              selectedLocation.IsEnable = true;
              setLocations(newlocations);
            } else {
              selectedLocation.IsEnable = false;
              setLocations(newlocations);
              toast.error("Something went wrong");
            }
          })
          .catch((e) => {
            setLoader(false);
            toast.error("Something went wrong");
          });
      }
    }
    }
  };

  const getLocations = () => {
    setLoader(true);
    setLocations([]);
    Api.getIntegrationLocations({ IntegrationId: id })
      .then((resp) => {
        setLoader(false);
        if (resp.data && resp.data.Result) {
          for (let index = 0; index < resp.data.Result.length; index++) {
            const element = resp.data.Result[index];
            if (element.LastSyncDateTime) {
              try {
                const date = new Date(element.LastSyncDateTime);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();

                if (dt < 10) {
                  dt = "0" + dt;
                }
                if (month < 10) {
                  month = "0" + month;
                }

                element.LastSyncDateTime = month + "-" + dt + "-" + year;
              } catch (error) { }
            }
          }
          setLocations(resp.data.Result);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const enableDisable = (type) => {
    var status = null;
    if (type == "patient") {
      status = !IsEnablePatient;
    } else if (type == "appointment") {
      status = !IsEnableAppointment;
    }
    Api.enableDisableTypes(status, type)
      .then((resp) => {
        if(resp.data){
          if (type == "patient") {
            SetIsEnablePatient(status);
          } else if (type == "appointment") {
            SetIsEnableAppointment(status);
          }
        }else {
          toast.error("Something went wrong.");
        }
        
      });
  }

  const [agencyLoader, SetAgencyLoader] = useState(false);

  const addUpdateAgencyKey = () => {
    if (AgencyKey && AgencyKey.length && AgencyKey.length > 0) {
      SetAgencyLoader(true);
      Api.addUpdateAgencyKey({ AccountId: accountId, APIKey: AgencyKey })
        .then((resp) => {
          SetAgencyLoader(false);
          if (resp.data.Result) {
            SetIsAgencyKey(true);
            SetOriginalAgencyKey(AgencyKey);
            toast.success("Key successfully updated.");
          } else {
            toast.error("Something went wrong.");
          }
        }).catch((e) => {
          SetAgencyLoader(false);
          toast.error("Something went wrong.");
        })

    } else {
      toast.error("Please enter the key")
    }
  }

  const getAgencyAccountDetails = () => {
    Api.getAgencyAccountDetails()
      .then((resp) => {
        if (resp.data.Result) {
          if (resp.data.Result.AgencyKey) {
            SetAgencyKey(resp.data.Result.AgencyKey);
            SetIsAgencyKey(true);
          } else {
            SetAgencyKey(null);
            SetIsAgencyKey(false);
          }
          // SetAccountId(resp.data.Result.AccountId);
          SetIsEnablePatient(resp.data.Result.IsEnablePatient);
          SetIsEnableAppointment(resp.data.Result.IsEnableAppointment);
        }
      })
  }

  const handelAgencyKeyChange = (event) => {
    SetAgencyKey(event.target.value)
  }


  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userData"));
    if (userInfo != null && userInfo.UserId && userInfo.UserId > 0 && userInfo.AccountId) {
      SetAccountId(userInfo.AccountId);
    }
    SetAgencyKey()
    getLocations();
    getAgencyAccountDetails();
  }, [id]);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <TopNavBar />
          <div className="white-container position-relative">
            <div className="page-info">
              <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link exact to="/setting/integration">
                      Integrations{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {decodeURI(name)}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="app-content">
              {id == 14 && (
                <div className='row app-box-wrapper'>
                  <div className='box-col crm-box'>
                    <div className='crm-info-row'>
                      <div className='crm-head'>Agency Key</div>
                      <p>
                      You can find Agency API key at{' '}
                        <strong>
                          Login -&gt; Setting -&gt; API Keys
                        </strong>{' '}
                        tab in Go High Level account. (After saving Agency key, click Sync Patients or Sync Appointments to start synchronization)
                      </p>
                    
                      <div className='crm-input locationInfo-col'>
                        {/* <div class='form-group app-ctrl has-value'>
                        <label class=''>Key</label>
                        <input
                          type='text'
                          className='form-control'
                          defaultValue=''
                          onChange={inputFun.checkInputHasValue}
                        />
                      </div> */}
                        <div style={{ display: 'flex', alignItems: ' center' }}>
                          <div className='form-group'>
                            <p className='icon-label'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 14 14'
                              >
                                <defs />
                                <path
                                  fill='#0A2753'
                                  d='M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.292-3.122c0-.745-.187-1.475-.544-2.125l5.286-5.281a.433.433 0 00.086-.484.309.309 0 00-.07-.099L12.558.412a.336.336 0 00-.099-.068.43.43 0 00-.484.085l-.731.732L10.19.108a.339.339 0 00-.1-.069.43.43 0 00-.483.085L8.44 1.291a.338.338 0 00-.069.1.43.43 0 00.086.483l1.036 1.037-2.8 2.8a4.408 4.408 0 00-2.124-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.549-.64c.584 0 1.135.227 1.548.64.414.414.643.965.643 1.55 0 .586-.227 1.135-.64 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.549-.642 2.177 2.177 0 01-.64-1.548 2.155 2.155 0 01.639-1.55z'
                                />
                              </svg>
                              Agency key
                            </p>
                            <div className='info-ctrl mb-3'>
                              <input
                                type='text'
                                className='form-control'
                                value={AgencyKey}
                                name="agencykey"
                                onChange={handelAgencyKeyChange}
                              />

                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn app-btn large-btn ml-2"
                            onClick={addUpdateAgencyKey}
                          >
                            {agencyLoader && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                              />
                            )}
                            Save
                          </button>
                        </div>

                        <div className='crm-switch'>
                          <div className='switch-box'>
                            <div className='single-switch'>
                              <span>Sync Patients</span>
                              <Form.Check
                                type='switch'
                                id='crm-switch'
                                className='app-switch'
                                defaultChecked={IsEnablePatient}
                                checked={IsEnablePatient}
                                disabled={!isAgencyKey}
                                onChange={() => enableDisable("patient")}
                              //   onChange={(e) => {
                              //     if (!LocationAisActive) {
                              //       SetNewLocationEnabledModel(true)
                              //     }
                              //     SetLocationAState(e.target.checked)
                              //   }}
                              />
                            </div>
                            <div className='single-switch'>
                              <span>Sync Appointments</span>
                              <Form.Check
                                type='switch'
                                id='crm-switch1'
                                className='app-switch'
                                // label="Sync Appointments"
                                defaultChecked={IsEnableAppointment}
                                checked={IsEnableAppointment}
                                disabled={!isAgencyKey}
                                onChange={() => enableDisable("appointment")}
                              //   onChange={(e) => {
                              //     if (!LocationAisActive) {
                              //       SetNewLocationEnabledModel(true)
                              //     }
                              //     SetLocationAState(e.target.checked)
                              //   }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row app-box-wrapper">
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box add-location-box d-flex align-items-center justify-content-center">
                    <div className="add-location">
                      <button
                        className="btn add-btn-round"
                        onClick={() => SetAddLocationModel(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <path d="M22 9.8v2.4a1 1 0 01-1 1h-7.799V21a1 1 0 01-1 1h-2.4a1 1 0 01-1-1v-7.8H1a1 1 0 01-1-1V9.8a1 1 0 011-1h7.801V1a1 1 0 011-1h2.4a1 1 0 011 1v7.8H21a1 1 0 011 1z" />
                        </svg>
                      </button>
                      <span>ADD NEW LOCATION</span>
                    </div>
                  </div>
                </div>
                {locations.length > 0 &&
                  locations.map((location, index) => (
                    <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                      <div className="app-box">
                        <Form.Check
                          type="switch"
                          id={"custom-switch-" + index}
                          className="app-switch"
                          defaultChecked={location.IsEnable}
                          checked={location.IsEnable}
                          onChange={(e) => enableDisableLocation(location)}
                        />
                        <h4 className="box-title">{location.LocationName}</h4>
                        {location.LastSyncDateTime ? (
                          <p>
                            Last sync: <span>{location.LastSyncDateTime}</span>
                          </p>
                        ) : (
                          ""
                        )}
                        <div
                          className={`key-info ${!location.IsEnable ? "not-visible" : ""
                            }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 14 14"
                            className="info-icon key-icon"
                          >
                            <path d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z" />
                          </svg>
                          <span className="lbl-text">Key</span>
                          <CopyToClipboard text={location.AccountKey}>
                            <button
                              className="btn small-ico-btn copy-btn"
                              onClick={() => coppyMessage()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 14 14"
                                className="info-icon"
                              >
                                <defs />
                                <g clip-path="url(#clip0)">
                                  <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z" />
                                  <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z" />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <path d="M0 0h14v14H0z" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </button>
                          </CopyToClipboard>
                          <button
                            className="btn small-ico-btn info-btn"
                            onClick={() =>
                              setLocationKeyInfoModel(true, location )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              className="info-icon tooltip-icon"
                            >
                              <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z" />
                            </svg>
                          </button>
                        </div>
                        <p className="location-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 12 16"
                            className="info-icon"
                          >
                            <path d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z" />
                          </svg>
                          {location.Address}
                        </p>
                        <div className="btn-box">
                          {id != 14 &&
                          <Link
                            exact
                            to={"/" + id + "/manage"}
                            className={`btn btn-block app-btn text-uppercase ${!location.IsEnable
                              ? "lightgray-btn  disabled"
                              : ""
                              }`}
                          >
                            connect
                          </Link>
}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="row app-box-wrapper mt-2">
                  <Calendly/>
         <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="hippa-img-box d-flex align-items-center justify-content-center">
                    <img src={HIPPAlogo} alt="HIPPA Logo" />
                  </div>
                </div>
              </div>
            </div>

            {loader && <AppPageLoader></AppPageLoader>}
          </div>
        </div>
      </main>
      <AddLocationModel
        show={AddLocationModelShow}
        reload={getLocations}
        onHide={() => SetAddLocationModel(false)}
        modelState={AddLocationModelShow}
      />
      <NewLocationEnabledModel
        location={selectedApiKey}
        show={NewLocationEnabledModelShow}
        reload={getLocations}
        integrationId={id}
        onHide={(status, data, isSuccess) =>
          setNewLocationEnabledModel(status, data, isSuccess)
        }
      />
      <LocationKeyInfoModel
        location={selectedLocations}
        intGrId={id}
        reloadLocation ={()=>getLocations()}
        show={LocationKeyInfoModelShow}
        onHide={() => setLocationKeyInfoModel(false, null)}
      />
      <ToastContainer />
    </div>
  );
}
