import React, { useEffect, useState } from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import {
  Logo,
  ProductName,
  ThemeColor,
  Title,
  FavIcon,
} from "../src/config/appConstatnts";
import "./App.scss";

// component imports
import IntegrationDirectoryPage from "./app-pages/IntegrationDirectoryPage";
import HighlevelPage from "./app-pages/HighlevelPage";
import IntegrationLocationPage from "./app-pages/IntegrationLocationPage";
import ManageConnectionPage from "./app-pages/ManageConnectionPage";
import ComingSoon from "./app-pages/ComingSoon";
import BillingPage from "./app-pages/BillingPage";
import ProfilePage from "./app-pages/ProfilePage";
import Setting from "./app-pages/Setting";
import PaymentMethodPage from "./app-pages/PaymentMethodPage";
import SignInPage from "../src/auth-pages/SignInPage";
import SignUpPage1 from "../src/auth-pages/SignUpPage1";
import SignUpPage2 from "../src/auth-pages/SignUpPage2";
import ResetPasswordPage from "../src/auth-pages/ResetPasswordPage";
import ForgotPasswordPage from "../src/auth-pages/ForgotPasswordPage";
import ErrorPage from "./app-pages/ErrorPage";
import AccountSetup from "./auth-pages/AccountSetup";
// import ChangePassPage from '../src/auth-pages/ChangePassPage';
import EagleSoftSystemSetup from "../src/app-pages/EagleSoftSystemSetup";
import DentrixEnterpriseSystemsetup from "../src/app-pages/DentrixEnterpriseSystemsetup";
import OpendentalSystemsetup from "../src/app-pages/OpendentalSystemsetup";
import AccountSetting from "../src/app-pages/AccountSetting";
import EagleSoftLocation from "../src/app-pages/EagleSoftLocation";
import DentrixEnterpriseLocation from "../src/app-pages/DentrixEnterpriseLocation";
import OpendentalLocation from "../src/app-pages/OpendentalLocation";
import CurveDentelLocation from "../src/app-pages/CurveDentelLocation";
import TDOLocation from "../src/app-pages/TDOLocation";
import InFusionSoftLocation from "../src/app-pages/InFusionSoftLocation";
import TeamMember from "../src/app-pages/TeamMember";
import Locations from "../src/app-pages/Locations";

import { ToastContainer, toast } from "react-toastify";
// import ChangePassword from '../src/app-pages/ChangePassword'

import Api from "./API/login";
import "react-toastify/dist/ReactToastify.min.css";

import history from "./history";
import AuthKey from "./auth-pages/AuthKey";

function App() {
  useEffect(() => {
    document.title = Title;
    document.getElementById("favicon").href = FavIcon;
    let domain = window.location.hostname;
    if (
      localStorage.getItem("CompanyId") == undefined ||
      localStorage.getItem("CompanyId") == null
    ) {
      Api.getCompanyId(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", res.data.Result);
          window.location.reload();
        })
        .catch((error) => {});
    }
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") != undefined &&
        localStorage.getItem("token").length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == undefined ||
        localStorage.getItem("token").length == 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/setting/profile" />
        )
      }
    />
  );

  return (
    <>
      <Switch>
        <PublicRoute path="/" component={SignInPage} exact />
        <PublicRoute path="/signin" component={SignInPage} exact />
        <PublicRoute path="/signup" component={SignUpPage1} exact />
        <PublicRoute path="/authKey/:token" component={AuthKey} exact />
        {/*   <Route path="/ChangePassPage" component={ChangePassPage} exact /> */}
        <PrivateRoute path="/setting/comingSoon" component={ComingSoon} exact />
        <PrivateRoute
          path="/setting/accountsetting"
          component={AccountSetting}
          exact
        />
        <PrivateRoute path="/setting/teammember" component={TeamMember} exact />
        <PrivateRoute path="/setting/location" component={Locations} exact />
        {/* <Route path="/setting/changepassword" component={ChangePassword} exact /> */}
        <PublicRoute
          path="/resetpassword"
          component={ResetPasswordPage}
          exact
        />
        <PublicRoute
          path="/ForgotPassword"
          component={ForgotPasswordPage}
          exact
        />
        <PrivateRoute
          exact
          path="/setting/integration"
          component={IntegrationDirectoryPage}
        />
        <PrivateRoute
          exact
          path="/integration/location/:id/:name"
          component={IntegrationLocationPage}
        />
        <PrivateRoute exact path="/highlevel" component={HighlevelPage} />
        {/* <PrivateRoute exact path="/eaglesoftlocation" component={EagleSoftLocation} />
      <PrivateRoute
        exact
        path="/dentrixenterpriselocation"
        component={DentrixEnterpriseLocation}
      /> */}
        <PublicRoute path="/activation" component={AccountSetup} exact />
        <PrivateRoute
          exact
          path="/opendentallocation"
          component={OpendentalLocation}
        />

        <PrivateRoute
          exact
          path="/curvedentellocation"
          component={CurveDentelLocation}
        />
        <PrivateRoute exact path="/tdolocation" component={TDOLocation} />
        <PrivateRoute
          exact
          path="/infusionsoftlocation"
          component={InFusionSoftLocation}
        />

        <PrivateRoute
          exact
          path="/:id/manage"
          component={ManageConnectionPage}
        />
        {/* <PrivateRoute exact path="/eaglesoft/manage" component={EagleSoftSystemSetup} />
      <PrivateRoute
        exact
        path="/dentrixenterprise/manage"
        component={DentrixEnterpriseSystemsetup}
      />
      <PrivateRoute
        exact
        path="/opendental/manage"
        component={OpendentalSystemsetup}
      /> */}
        <PrivateRoute exact path="/setting/billing" component={BillingPage} />
        <PrivateRoute
          exact
          path="/billing/paymentmethod"
          component={PaymentMethodPage}
        />
        <PrivateRoute exact path="/setting/profile" component={ProfilePage} />
        <PrivateRoute exact path="/setting" component={Setting} />
        <Route component={ErrorPage} />
      </Switch>
      <ToastContainer hideProgressBar />
    </>
  );
}
export default App;
