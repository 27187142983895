import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { Spinner } from "reactstrap";

import Icon1 from "../assets/images/icon-2.png";
import Icon2 from "../assets/images/eye.png";
import Icon3 from "../assets/images/logo-white.png";
import { validateForm } from "../config/commonFun";
import { validPasswordRegex } from "../config/appRegex";
import Api from "../API/login";

export default function ResetPasswordPage() {
  const [eyeTog1, seteyeTog1] = useState("false");
  const handleToggleeyeTog1 = () => {
    seteyeTog1(!eyeTog1);
  };
  const [eyeTog2, seteyeTog2] = useState("false");

  const [eyeTog3, seteyeTog3] = useState("false");

  let history = useHistory();

  const redirectToSignup = () => {
    history.push("/signup");
  };
  const redirectToSignin = () => {
    history.push("/signin");
  };

  const handleToggleeyeTog2 = () => {
    passwordTextBoxType == "password"
      ? setPasswordTextBoxType("text")
      : setPasswordTextBoxType("password");
    seteyeTog2(!eyeTog2);
  };

  const handleToggleeyeTog3 = () => {
    conPasswordTextBoxType == "password"
      ? setConPasswordTextBoxType("text")
      : setConPasswordTextBoxType("password");
    seteyeTog3(!eyeTog3);
  };

  // Set loader model
  const [loader, setLoader] = useState(false);

  // Set response message model
  const [rMessage, setRMessage] = useState("");

  // Set password field as password.
  const [passwordTextBoxType, setPasswordTextBoxType] = useState("password");

  // Set confirm password field as password.
  const [conPasswordTextBoxType, setConPasswordTextBoxType] =
    useState("password");

  // Set reset password model
  const [resetPasswordModel, setResetPasswordModel] = useState({
    Password: "",
    ConPassword: "",
    Email: "",
  });

  // Set reset password error model
  const [resetPasswordErrorModel, setResetPasswordErrorModel] = useState({
    Password: "",
    ConPassword: "",
  });

  // Get encrypted email from query string
  useEffect(() => {
    const qString = queryString.parse(window.location.search);
    if (qString != null && qString.Eamil != undefined) {
      setResetPasswordModel((resetPasswordModel) => ({
        ...resetPasswordModel,
        ["Email"]: qString.Eamil,
      }));
    }
  }, []);

  // Handel change event of reset password form
  const handelChanges = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    setResetPasswordModel((resetPasswordModel) => ({
      ...resetPasswordModel,
      [name]: value,
    }));

    let errorMsg = "";
    switch (name) {
      case "Password":
        if (value.length == 0) {
          errorMsg = "New Password is required.";
        } else {
          errorMsg = validPasswordRegex.test(value)
            ? ""
            : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length == 0) {
          errorMsg = "Confirm New Password is required.";
        } else if (resetPasswordModel.Password != value) {
          errorMsg = "Password don't match.";
        }
        break;
      default:
        break;
    }

    setResetPasswordErrorModel((resetPasswordErrorModel) => ({
      ...resetPasswordErrorModel,
      [name]: errorMsg,
    }));
  };

  // Handel submit event
  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (resetPasswordModel.Password.length == 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        ["Password"]: "New Password required.",
      }));
      flagError = true;
    }
    if (resetPasswordModel.ConPassword.length == 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        ["ConPassword"]: "Confirm Password required.",
      }));
      flagError = true;
    }
    if (validateForm(resetPasswordErrorModel) && !flagError) {
      setLoader(true);
      Api.resetPassword(resetPasswordModel)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => redirectToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setRMessage(JSON.parse(error.request.response).Message);
        });
    }
  };
  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Reset Password</h1>
                      <p className="mb-2">
                        Your new password must be different from old passwords.
                      </p>
                    </div>
                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <span className="input-icon1">
                            <img
                              src={Icon1}
                              alt="views"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type={passwordTextBoxType}
                            className="form-control light-border pass-input"
                            id="Password"
                            placeholder="New Password*"
                            name="Password"
                            value={resetPasswordModel.Password}
                            onChange={handelChanges}
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog2 ? "passVisible" : ""
                              }`}
                              onClick={handleToggleeyeTog2}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">
                          {resetPasswordErrorModel.Password}
                        </div>

                        <div className="form-group">
                          <span className="input-icon1">
                            <img
                              src={Icon1}
                              alt="views"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type={conPasswordTextBoxType}
                            className="form-control light-border pass-input"
                            id="ConPassword"
                            placeholder="Confirm New Password*"
                            name="ConPassword"
                            value={resetPasswordModel.ConPassword}
                            onChange={handelChanges}
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog3 ? "passVisible" : ""
                              }`}
                              onClick={handleToggleeyeTog3}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">
                          {resetPasswordErrorModel.ConPassword}
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className="btn btn-outline-primary   blue-btn-bg "
                          >
                            {loader && (
                              <Spinner style={{ verticalAlign: "middle" }} />
                            )}
                            Save
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a
                              href="#"
                              className="h5 f-600 pl-2"
                              onClick={redirectToSignin}
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {/* <img src={Icon3} className="img-fluid logo-white" /> */}
                  <h1>Welcome</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
